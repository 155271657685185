import {React, useRef} from 'react';
import Youtube from  'react-youtube';
import Close from './svg/Close';


const PopupYoutube = ({idVideo, openPlayer, setOpenPlayer, setActivePopup}) => {

    const playerRef = useRef(null);

    const pauseVideo = () => {
        if (playerRef.current) {
            playerRef.current.internalPlayer.pauseVideo();
        }
    };
    
    const opts = {
        height: '390',
        width: '640',
        playerVars: {
          // Aquí puedes agregar opciones personalizadas para el reproductor de YouTube
        },
      };

      const closePlayer = e => {
        setOpenPlayer(false);
        setActivePopup(false);
        pauseVideo();
      }

  return (
    <div className={`${openPlayer ? 'block': 'hidden'} 
    fixed transition duration-200 
    left-0 top-0 w-full h-[100vh] 
    z-50 bg-[rgba(0,0,0,0.9)] overflow-x-hidden overflow-y-auto`}>
        <div className='relative px-[20px] lg:px-[70px] py-[40px] 
        lg:py-[80px] xg:py-[90px] flex justify-center h-full  mx-auto items-center'> 
            <div className='container-youtube w-[90%] max-w-[640px] relative'>
                <span className='close block absolute 
                            right-[-25px] top-[-25px] 
                            w-[50px] h-[50px] overflow-hidden
                             bg-[#FF5F8A] rounded-[50%] p-[13px] cursor-pointer
                             hover:bg-[#2C4252]  z-10'
                        onClick={closePlayer}>
                    <Close />
                </span>
                <Youtube videoId="cydEMqD514c" opts={opts} ref={playerRef} /> 
            </div>

        </div>  
    </div>
  )
}

export default PopupYoutube