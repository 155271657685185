import {useState, useEffect} from 'react';
import './App.css';
import HamburgerMenu from './components/HamburgerMenu';
import MainContent from './components/MainContent'; 
import { useMediaQuery } from 'react-responsive';
import Logo from './components/Logo';
import Popup from './components/Popup';
import PopupYoutube from './components/PopupYoutube';
import BgSvg from './components/svg/BgSvg';
import BackButton from './components/BackButton';
import Close from './components/svg/Close';

function App() {
    const [darkMode, setDarkMode] = useState(false);
    const [openMenu, setOpenMenu] = useState(false);
    const [openPlayer, setOpenPlayer] = useState(false);
    const [activePopup, setActivePopup] = useState(false);
    const isMobile = useMediaQuery({maxWidth: 1023});
    const [isAbout, setIsAbout] = useState(false);
    
    useEffect(() => {
      if(activePopup){
        document.body.classList.add('open-popup')
      }else{
        document.body.classList.remove('open-popup')
      } 
    }, [activePopup])

    useEffect(() => {
      if(darkMode){
        document.body.classList.add('dark')
      }else{
        document.body.classList.remove('dark')
      } 
    }, [darkMode])
    

  return (
    <div className={ "bg-site w-full min-h-[100vh] z-10" }>
        
    { isMobile && <div className='absolute max-w-[130px] min-[360px]:max-w-[190px] min-[420px]:max-w-[238px] top-[40px] left-[25px]'> <Logo/></div>}
        
        <div className='absolute right-[20px] z-50 lg:left-[35px] top-[45px] min-[360px]:top-[55px] min-[420px]:top-[60px]
           lg:top-[35px] flex justify-between roboto-flex items-center
           max-w-[1440px] mx-auto pl-[25px] xl:pl-[60px]'>
          { !isAbout && <HamburgerMenu 
              openMenu={openMenu} 
              setOpenMenu={setOpenMenu} 
              setActivePopup={setActivePopup} 
              darkMode={darkMode}
              setIsAbout={setIsAbout}
               />} 
                 
        </div>
        <Popup 
            openMenu={openMenu} 
            setActivePopup={setActivePopup}
            darkMode={darkMode}
            isAbout={isAbout}
            setIsAbout={setIsAbout}
            setOpenMenu={setOpenMenu}/>
        <PopupYoutube 
            openPlayer={openPlayer} 
            setOpenPlayer={setOpenPlayer} 
            setActivePopup={setActivePopup} 
            idVideo="cydEMqD514c" />
        <MainContent 
              darkMode={darkMode}
              setDarkMode={setDarkMode}
              setOpenPlayer={setOpenPlayer} 
              openPlayer={openPlayer} 
              setActivePopup={setActivePopup} />

        {/* <div className='absolute top-0 left-0 w-full h-full z-[-1] '>
            { <BgSvg /> }
        </div> */}
    </div>
  );
}

export default App;
