import React from 'react'

const Cactus = () => {
  return (
    <>
        <svg width="155" height="99" viewBox="0 0 155 99" fill="none" 
          xmlns="http://www.w3.org/2000/svg" className='element-item' id='cactus'>
            <path fillRule="evenodd" clipRule="evenodd" d="M109.934 9.22563C107.416 10.486 106.289 15.9586 106.919 23.9187C107.814 35.0628 107.714 36.688 105.925 36.688C105.196 36.688 105.096 36.4559 104.433 33.3713C103.373 28.5952 103.041 27.4676 102.18 26.2072C100.755 24.0845 98.6671 24.6484 97.2753 27.4676C96.5793 28.8937 96.4799 29.4908 96.4799 33.2055C96.5131 38.0479 97.0101 39.9716 98.8328 42.1274C100.523 44.0843 101.683 44.615 104.301 44.5486L106.521 44.5155L106.919 47.8322C107.283 50.8836 107.383 51.1821 108.377 52.0112C111.127 54.3329 115.535 55.328 118.484 54.2334L120.174 53.6364V47.4342V41.2651L122.362 41.0661C124.748 40.8339 127.101 39.7062 127.962 38.3795C128.625 37.3514 129.023 33.8688 129.321 26.5389L129.52 20.3698L128.758 19.8059C127.664 19.0763 125.444 19.1094 124.416 19.9386C123.256 20.8341 122.13 23.6202 121.699 26.7047C121.301 29.6234 121.135 29.7893 118.518 29.8556L117.358 29.8888V22.4925C117.358 16.0581 117.291 14.9304 116.728 13.5042C115.9 11.3815 114.11 9.42463 112.619 8.99346C111.094 8.59546 111.293 8.56229 109.934 9.22563Z" fill="#468C45"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M27.5503 24.2506C26.5561 24.6486 25.5288 25.9422 25.1311 27.2357C24.9323 27.8659 24.7003 30.6187 24.6009 33.3716C24.5015 36.0913 24.3026 39.7397 24.1701 41.398L23.9713 44.4826H22.2149C20.094 44.4826 19.9283 44.2504 18.8015 39.7065C18.0062 36.423 17.0783 34.3998 16.283 34.0681C14.2615 33.3053 12.1737 34.2671 11.1132 36.423C9.9865 38.6783 10.0528 43.7529 11.2458 49.2255C12.6376 55.5272 13.5987 56.5554 18.437 56.6218L21.2538 56.6549L21.4527 61.1657C21.5852 63.62 21.7841 65.8422 21.9166 66.0412C22.0492 66.2734 22.2149 67.0362 22.248 67.7328C22.3806 69.2916 23.2422 70.8505 24.5015 71.8123C26.4567 73.2717 31.9909 72.343 33.9793 70.2203C34.5758 69.5901 34.6752 69.0263 34.7415 64.7145L34.8409 59.9053L38.3868 59.7063C42.6949 59.4741 44.5507 58.6781 46.1083 56.4559C47.0693 55.0961 47.1024 54.8639 47.235 51.0828C47.3344 47.7993 47.235 46.8043 46.6385 44.9138C45.9094 42.5921 45.3461 41.6965 43.9542 40.7347C42.8606 39.9719 42.1978 40.0382 41.038 41C39.9444 41.8956 38.3868 45.5439 37.4258 49.3582C36.9618 51.2819 36.8293 51.4477 36.0008 51.4477H35.106L34.9072 47.8988C34.7746 45.9751 34.6752 41.3649 34.6752 37.7165C34.6752 28.297 34.145 25.0466 32.488 24.2838C31.527 23.8526 28.5445 23.8195 27.5503 24.2506Z" fill="#468C45"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M72.3877 49.7893C71.9237 50.1541 71.1947 51.1491 70.7638 52.0115C70.0016 53.5372 69.9685 53.902 70.0348 59.6399C70.0679 66.5055 70.2005 66.2733 67.2511 65.8753C64.2354 65.4773 64.368 65.5768 63.6389 61.9948C63.0424 58.8771 62.3465 57.5835 60.9878 56.9202C59.4302 56.1905 58.2372 56.3895 57.4087 57.5172C55.5198 60.0711 57.0442 71.2484 59.6622 73.8686C61.0872 75.2948 62.9761 75.7591 65.9918 75.4938L68.3778 75.2616L69.0075 79.1754L69.6703 83.1222L71.9569 83.0227C74.5749 82.8901 76.5632 82.4589 77.6899 81.7624C78.419 81.2981 78.419 81.1986 78.419 74.2335C78.419 64.7145 77.8888 55.0297 77.226 52.8075C76.2981 49.6234 74.2103 48.3299 72.3877 49.7893Z" fill="#468C45"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M111.094 3.02386C110.034 4.48322 109.04 5.67723 108.907 5.67723C108.741 5.67723 107.582 5.21289 106.322 4.68222C102.611 3.12336 102.279 3.32237 103.903 6.20791C106.289 10.3538 111.326 12.5428 115.701 11.3156C117.49 10.8181 120.174 8.52961 121.566 6.37374C122.461 4.94756 122.792 3.35553 122.196 3.35553C122.063 3.35553 120.771 3.88621 119.346 4.54955C117.954 5.21289 116.662 5.6109 116.529 5.44506C116.363 5.3124 115.667 4.15155 114.938 2.85803C114.242 1.59768 113.513 0.536327 113.348 0.469993C113.182 0.403659 112.155 1.56451 111.094 3.02386Z" fill="#DD211E"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M67.0854 17.2528C63.3076 18.48 59.5297 21.1002 54.6582 25.8762C53.3327 27.1697 52.2391 28.4301 52.2391 28.6623C52.2391 29.8895 55.8513 32.2112 59.7948 33.5378C65.5942 35.4615 72.8185 35.6274 79.4795 33.9027C82.2632 33.173 86.4056 31.382 87.698 30.2875L88.5265 29.6241L86.9358 27.3356C84.0527 23.057 78.6842 18.679 74.5749 17.2528C72.3546 16.4568 69.5709 16.4568 67.0854 17.2528Z" fill="#FDE964"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M132.005 41.1331C129.188 42.0617 122.328 45.2458 121.069 46.2076C120.009 47.0036 120.638 48.1645 122.925 49.4912C126.007 51.3485 126.835 51.7134 129.685 52.5094C131.906 53.1727 132.999 53.2722 137.407 53.2391C143.504 53.2059 146.023 52.6421 151.093 50.2209L154.341 48.662L152.85 48.065C152.021 47.7665 149.436 46.3403 147.117 44.9473C138.6 39.8064 137.109 39.4084 132.005 41.1331Z" fill="#FDE964"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M105.925 49.4243C103.406 50.2203 101.749 51.1158 97.3747 54.0345C93.0003 56.9201 90.3492 59.2086 90.3492 60.0709C90.3492 61.3976 95.3532 63.7525 101.053 65.1123C103.638 65.7425 105.063 65.842 110.233 65.842C116.496 65.842 118.385 65.6098 122.494 64.3163C125.51 63.3545 129.453 61.1655 129.453 60.4358C129.453 59.7724 124.781 55.1622 122.295 53.3712C116.794 49.3579 110.63 47.8986 105.925 49.4243Z" fill="#FDE964"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M17.8074 65.7423C15.5871 66.1403 12.6708 67.3011 10.2848 68.7273C7.03718 70.6842 2.76222 75.7919 1.46979 79.2413C1.00584 80.4685 1.1384 80.6012 4.58488 82.4585C14.1621 87.5663 35.2718 86.0406 41.3363 79.772L42.4299 78.6443L40.8392 76.3226C37.6578 71.646 32.3887 67.6991 27.3847 66.2398C25.1312 65.5764 20.1935 65.3111 17.8074 65.7423Z" fill="#FDE964"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M70.6313 80.8007C67.715 81.5967 65.2958 82.6249 63.3075 83.9184C61.0872 85.3446 57.4087 89.1589 56.3151 91.1821L55.5529 92.5087L56.9779 93.3379C61.6174 96.0245 65.5941 97.5833 69.6371 98.2467C74.5086 99.0095 82.7602 98.0476 88.9241 95.925C91.7078 94.9631 96.3142 92.4756 96.3142 91.9117C96.3142 90.9499 88.3939 84.7808 84.6823 82.7908C80.5399 80.6017 74.5085 79.7394 70.6313 80.8007Z" fill="#FDE964"/>
            <path d="M113.149 0.569807C112.917 0.801977 116.496 6.00922 116.894 6.00922C117.059 6.00922 118.153 5.64438 119.313 5.21321L121.5 4.45036L120.771 5.71071C119.71 7.60124 117.556 9.6576 115.866 10.4204C114.077 11.2496 111.227 11.2828 109.504 10.5531C107.913 9.88977 105.427 7.56807 104.367 5.77705C103.903 4.98104 103.406 4.35086 103.24 4.35086C102.577 4.35086 103.008 5.67755 104.234 7.36907C106.455 10.4536 109.139 11.913 112.95 12.112C114.972 12.2115 115.369 12.3441 115.899 13.0075C117.258 14.8317 117.623 18.5464 117.192 26.5728C117.092 28.4634 117.159 29.7901 117.358 29.8896C117.888 30.1549 118.153 27.7337 118.219 22.0953C118.319 16.3574 118.053 14.6658 116.662 12.5431C116.131 11.7471 115.999 11.316 116.264 11.316C117.192 11.2828 119.81 8.96109 121.135 7.00423C123.19 3.88652 122.826 2.82517 120.108 3.98602C117.192 5.24637 117.159 5.21321 115.535 2.79201C114.077 0.636141 113.547 0.138634 113.149 0.569807Z" fill="black"/>
            <path d="M104.93 14.8973C104.93 15.2622 107.615 17.2854 108.145 17.2854C108.907 17.2854 108.609 16.7879 107.184 15.6933C105.858 14.6652 104.93 14.3335 104.93 14.8973Z" fill="black"/>
            <path d="M111.89 24.0186C111.89 28.463 111.989 35.0301 112.122 38.6121C112.287 44.0516 112.42 45.1461 112.818 45.1461C113.248 45.1461 113.281 44.2506 113.116 38.4131C112.983 34.7316 112.784 28.1645 112.685 23.8196C112.552 19.5079 112.32 15.959 112.188 15.959C112.022 15.959 111.89 19.6074 111.89 24.0186Z" fill="black"/>
            <path d="M68.1128 16.6555C67.0523 16.755 65.2628 17.3188 64.0698 17.8827C61.8163 18.9108 58.8669 21.332 58.8669 22.1281C58.8669 22.8246 59.1652 22.6919 60.6896 21.332C62.7442 19.4084 64.832 18.3138 67.4831 17.75C73.8459 16.4233 81.0039 19.939 86.6707 27.1363C87.8638 28.662 88.9242 29.8892 89.0899 29.8892C89.7527 29.8892 89.1893 28.7283 87.433 26.5061C81.6667 19.2425 75.0057 15.8595 68.1128 16.6555Z" fill="black"/>
            <path d="M122.494 19.7395C122.494 20.5355 123.787 23.2552 124.151 23.2552C124.615 23.2552 124.582 22.8904 123.787 20.9667C123.091 19.3415 122.494 18.7777 122.494 19.7395Z" fill="black"/>
            <path d="M65.0971 20.9339C63.2413 22.1611 62.3465 23.1229 62.8104 23.3883C63.2413 23.6536 67.1848 20.6022 66.9529 20.2374C66.7209 19.8394 66.7872 19.8062 65.0971 20.9339Z" fill="black"/>
            <path d="M128.625 23.4215C128.492 25.312 128.459 27.1031 128.559 27.4016C128.691 27.7001 128.492 28.0317 128.128 28.2307C127.763 28.4298 127.465 28.7946 127.465 29.0599C127.465 29.6569 127.863 29.6901 128.426 29.1263C128.791 28.7946 128.824 29.2589 128.658 31.7796C128.426 35.1627 127.796 37.2854 126.769 38.3799C125.874 39.3417 123.422 40.3699 121.401 40.6353L119.843 40.8674L119.909 46.2073C119.976 50.1211 120.108 51.5804 120.44 51.7131C120.738 51.8126 120.837 51.1492 120.837 49.1924V46.5722L123.323 45.4445C124.681 44.8143 125.775 44.1178 125.808 43.8856C125.808 43.2886 125.311 43.3881 123.157 44.4826C122.063 45.0133 121.102 45.4777 121.003 45.4777C120.904 45.4777 120.837 44.6153 120.904 43.554C121.003 41.8293 121.069 41.6634 121.831 41.5639C124.615 41.2654 127.432 39.5076 128.492 37.4844C129.155 36.1245 129.884 30.4861 129.52 29.1594C129.42 28.7283 129.719 28.1312 130.414 27.4347C131.574 26.2407 131.442 25.6105 130.216 26.4066L129.453 26.9041V23.4215C129.453 21.4978 129.321 19.939 129.155 19.939C128.989 19.939 128.757 21.4978 128.625 23.4215Z" fill="black"/>
            <path d="M54.4262 25.8767C52.6367 27.6677 51.5762 28.9612 51.7419 29.2266C51.8745 29.4587 53.0675 28.4969 55.0558 26.54C57.8064 23.8203 58.436 22.9248 57.6075 22.9248C57.475 22.9248 56.05 24.2515 54.4262 25.8767Z" fill="black"/>
            <path d="M97.9712 24.516C97.9712 25.2788 99.7938 27.4678 100.059 27.0367C100.258 26.6718 98.634 23.9189 98.2363 23.9189C98.0706 23.9189 97.9712 24.1843 97.9712 24.516Z" fill="black"/>
            <path d="M28.644 24.449C28.0806 25.0129 28.4451 25.2451 29.837 25.2451C32.8526 25.2451 34.1451 27.7658 34.0125 33.5037C33.9462 35.2615 33.9462 39.9381 33.9794 43.885C34.0125 50.0872 34.0788 51.1154 34.5096 51.1154C34.9404 51.1154 35.0067 50.0209 35.0067 43.3875V35.6595L36.0009 34.9299C37.0282 34.167 37.2933 33.5368 36.5974 33.5368C36.3985 33.5368 35.9677 33.769 35.6695 34.0343C35.1392 34.4987 35.1061 34.3329 34.9073 31.58C34.7084 28.1969 33.88 25.9416 32.5544 25.0792C31.5933 24.449 29.0416 24.051 28.644 24.449Z" fill="black"/>
            <path d="M124.35 25.6437C124.251 26.0749 124.019 27.8328 123.82 29.5575C123.621 31.2822 123.29 33.2722 123.091 33.9687C122.395 36.2904 119.81 37.8492 116.629 37.8492C115.568 37.8492 115.204 37.9819 115.204 38.3468C115.204 38.7116 115.568 38.8443 116.629 38.8443C120.307 38.8443 123.157 36.9869 123.986 34.035C124.449 32.3767 125.145 27.5343 125.145 25.8427C125.145 24.7482 124.549 24.6156 124.35 25.6437Z" fill="black"/>
            <path d="M24.0707 25.4779C24.0707 25.8096 25.7939 27.5675 26.1253 27.5675C26.6555 27.5675 26.3904 26.6719 25.6282 25.9754C24.866 25.2789 24.0707 25.0136 24.0707 25.4779Z" fill="black"/>
            <path d="M58.0715 27.0698C55.8181 29.6568 56.4477 30.287 58.7343 27.7331C60.325 25.9753 60.6564 25.2456 59.8942 25.2456C59.7285 25.2456 58.9 26.0748 58.0715 27.0698Z" fill="black"/>
            <path d="M63.5726 27.0036C62.1145 28.4961 61.9157 28.8941 62.6116 28.8941C63.0424 28.8941 65.8261 26.2408 65.8261 25.8096C65.8261 25.1794 64.8319 25.7101 63.5726 27.0036Z" fill="black"/>
            <path d="M96.4136 27.7997C96.2811 28.1314 96.1816 30.0219 96.1816 32.0451C96.1485 34.8643 96.3473 36.2905 96.9107 38.3468C98.3025 43.1892 99.6944 44.4828 103.804 44.7481L106.223 44.9139L106.422 46.4396C106.554 47.2688 106.687 48.098 106.72 48.297C106.786 48.4628 106.223 48.7945 105.494 48.9603C103.903 49.3583 103.273 50.3533 104.798 49.9885C105.328 49.889 106.886 49.69 108.244 49.5905C111.525 49.3252 113.911 49.9553 119.18 52.5092C123.058 54.3666 123.521 54.6982 126.57 57.7828C128.327 59.5738 129.884 61.0663 129.951 61.0663C131.276 61.0663 125.775 55.0631 122.892 53.3715C117.822 50.3865 112.42 48.496 109.04 48.4628H107.648L107.416 46.5723C107.084 43.7862 107.118 43.8194 104.632 43.8194C101.384 43.7862 99.8269 42.9902 98.7996 40.7349C97.4741 37.8493 96.8444 34.2009 97.1095 30.4531C97.2421 28.3304 97.209 27.2358 96.977 27.2358C96.7781 27.2358 96.513 27.5012 96.4136 27.7997Z" fill="black"/>
            <path d="M29.2404 31.1167C29.0084 33.6706 28.5113 45.3454 28.18 55.4946C28.0143 61.4647 28.0474 62.3933 28.4782 62.3933C28.8759 62.3933 29.0084 61.1662 29.1741 54.4996C29.3067 50.1878 29.5387 43.6539 29.7375 40.0387C30.102 31.7801 30.1352 28.8945 29.7375 28.8945C29.5718 28.8945 29.3398 29.8895 29.2404 31.1167Z" fill="black"/>
            <path d="M99.4625 31.3152C98.9985 32.5092 100.921 38.5788 102.312 40.2371C103.671 41.9287 106.19 42.5588 109.205 41.9618C111.161 41.5638 110.133 41 107.681 41.0995C105.03 41.2321 103.737 40.7678 102.677 39.2753C101.948 38.2139 100.755 34.665 100.423 32.6087C100.192 31.1162 99.7607 30.5192 99.4625 31.3152Z" fill="black"/>
            <path d="M9.48944 34.8303C9.48944 35.1951 11.7098 37.1851 12.1406 37.1851C12.7702 37.1851 12.472 36.4886 11.4115 35.5268C10.3179 34.5649 9.48944 34.2664 9.48944 34.8303Z" fill="black"/>
            <path d="M14.3609 41.7295C14.8248 46.9368 16.3492 51.2485 18.1719 52.542C18.6358 52.9069 19.7957 53.2717 20.7236 53.3712C22.1154 53.5039 22.4137 53.4375 22.4137 52.9732C22.4137 52.5752 22.0823 52.4425 21.1875 52.4425C17.8405 52.4425 16.5812 50.154 15.2225 41.6964C14.8248 39.2752 14.1289 39.3083 14.3609 41.7295Z" fill="black"/>
            <path d="M133.43 40.469C129.984 41.2319 128.128 41.9947 128.128 42.6581C128.128 43.2883 128.095 43.2883 129.884 42.4922C132.237 41.4641 136.28 40.7344 138.5 40.9666C140.422 41.1656 143.04 42.1937 145.062 43.5868C145.692 44.0179 147.879 45.5105 149.9 46.9035C153.645 49.4573 154.44 49.8553 154.242 49.0262C154.109 48.396 145.029 42.2932 142.543 41.1324C139.826 39.872 137.075 39.673 133.43 40.469Z" fill="black"/>
            <path d="M41.5351 40.4031C41.0711 40.8342 41.3362 41.2322 41.9327 41.0664C43.0263 40.8011 44.5839 42.0614 45.4455 43.9188C46.4397 46.0083 46.4728 46.539 45.6112 47.3018C44.9815 47.8657 44.7164 48.7944 45.1804 48.7944C45.2798 48.7944 45.6775 48.529 46.042 48.1973C46.6716 47.6335 46.6716 47.6667 46.4728 50.4527C46.1414 54.5654 45.081 56.5886 42.5624 57.8822C41.038 58.645 40.1432 58.844 37.6909 59.0098C35.7688 59.1093 34.5427 59.3415 34.3107 59.6068C34.0787 59.9053 33.9793 61.5305 34.0456 64.3497C34.1782 68.5951 34.1782 68.6615 33.5154 68.2303C26.8875 63.9849 19.6632 63.4211 13.2342 66.6714C8.76037 68.9268 4.9825 72.5752 2.16567 77.3844C0.806961 79.7393 0.608126 80.3031 1.30405 80.3031C1.50288 80.3031 2.06625 79.6066 2.5302 78.7443C4.02147 75.8919 7.07027 72.1772 9.48944 70.2535C13.5987 66.9699 17.5091 65.5438 22.248 65.5769C27.5503 65.6433 31.991 67.6665 36.3653 72.0114C37.9229 73.5702 39.7455 75.7592 40.4415 76.8869C41.1705 78.0478 41.9327 78.9765 42.1647 78.9765C42.4298 78.9765 42.6287 78.8769 42.6287 78.7775C42.6287 78.0478 39.7787 74.1009 37.6909 71.9118L35.1723 69.2916L35.0729 64.7477L34.9404 60.1707L37.6909 59.9717C42.6287 59.6068 44.4182 58.7113 46.0751 55.7595C47.003 54.1011 47.1025 53.6036 47.2019 50.4527C47.3013 47.5008 47.4338 46.8707 48.0304 46.2405C48.7926 45.3781 48.5274 44.8143 47.6327 45.3781C47.1356 45.7098 47.003 45.5771 46.6716 44.3168C46.1414 42.3599 44.0536 40.1709 42.7281 40.1709C42.1978 40.1709 41.6345 40.2704 41.5351 40.4031Z" fill="black"/>
            <path d="M10.351 40.7351C9.95337 41.1331 10.1522 43.1563 10.9807 47.6671C12.3063 54.8312 13.6318 57.0534 16.6806 57.3187C18.6027 57.4845 21.1544 57.0534 21.0219 56.589C20.9556 56.39 19.8951 56.2905 18.4039 56.3569C16.1173 56.4564 15.819 56.4232 14.8911 55.6272C14.3277 55.1628 13.6318 54.1347 13.3004 53.3718C12.6045 51.614 11.1464 44.1182 11.1464 42.2608C11.1464 40.9341 10.815 40.2708 10.351 40.7351Z" fill="black"/>
            <path d="M134.027 43.2224C133.331 43.6204 132.767 44.1511 132.767 44.3833C132.767 44.6155 132.867 44.8145 132.966 44.8145C133.397 44.8145 136.081 43.0566 136.081 42.7913C136.081 42.2938 135.319 42.4596 134.027 43.2224Z" fill="black"/>
            <path d="M127.067 46.2068C125.841 46.9033 125.178 47.7988 125.841 47.7988C126.371 47.7988 129.122 46.1073 129.122 45.7756C129.122 45.2781 128.36 45.444 127.067 46.2068Z" fill="black"/>
            <path d="M72.4539 49.2918C71.8906 49.9883 72.3214 50.3531 73.1167 49.8556C74.4091 49.0596 76.1324 50.4194 77.0934 53.0065C77.8225 54.9633 77.955 56.2569 77.4248 56.5885C76.8946 56.9202 77.0271 57.8489 77.5905 57.6167C78.0213 57.4509 78.0876 58.7776 78.0876 68.1639C78.0876 74.1008 78.187 79.1422 78.2864 79.4075C78.419 79.8387 77.9882 79.905 75.3702 79.905C73.6801 79.905 71.6586 80.0377 70.8964 80.2367L69.5377 80.5352L69.14 77.7492L68.7423 74.93L66.1906 75.1621C61.9819 75.527 59.9936 74.2998 58.5686 70.5187C58.1709 69.4906 57.7733 67.6332 57.6738 66.3728C57.5081 64.6481 57.3093 64.0511 56.9448 64.0511C56.5471 64.0511 56.514 64.416 56.7128 66.2733C57.2099 70.9499 58.8337 74.2666 61.286 75.5601C62.0151 75.9581 63.175 76.124 65.0639 76.124C66.5552 76.1571 67.8144 76.2235 67.9139 76.323C67.9801 76.4225 68.179 77.4507 68.2784 78.6447L68.5104 80.8005L66.4226 81.8287C62.479 83.7856 59.0657 86.8701 56.4477 90.7507C55.4535 92.2432 55.3541 92.5749 55.9837 92.5749C56.2489 92.5749 57.0111 91.7457 57.6738 90.7507C59.7285 87.7656 63.7715 84.1504 66.522 82.8569C69.9022 81.2317 71.4598 80.8669 74.9394 80.8669C81.269 80.8337 87.3334 83.7192 93.5305 89.6562C94.9886 91.0824 96.3142 92.2432 96.4467 92.2432C97.6397 92.2432 92.6357 87.0691 89.0898 84.6479C86.5712 82.9564 83.5887 81.4639 81.3352 80.8005C79.5789 80.303 79.4794 80.2035 79.3138 79.0427C78.9161 76.721 79.0155 56.3564 79.38 55.9915C79.9103 55.4609 79.844 55.1955 79.1812 55.0297C78.7835 54.9302 78.3859 54.1673 78.0213 52.8738C77.5905 51.4145 77.1266 50.5853 76.2649 49.8224C74.9394 48.6948 73.183 48.4294 72.4539 49.2918Z" fill="black"/>
            <path d="M69.1401 49.7229C69.1401 50.2535 71.3935 52.6084 71.6255 52.3762C71.99 52.0446 70.1674 49.4575 69.604 49.4575C69.3389 49.4575 69.1401 49.5902 69.1401 49.7229Z" fill="black"/>
            <path d="M98.7665 52.3101C93.4642 55.6268 88.3939 60.0712 89.9183 60.0712C90.0177 60.0712 90.9788 59.3415 92.0061 58.446C94.6903 56.1243 98.8327 53.1724 100.887 52.1774C102.478 51.3814 102.876 50.8175 102.08 50.5522C101.882 50.4859 100.423 51.2819 98.7665 52.3101Z" fill="black"/>
            <path d="M105.262 52.0116C103.87 52.9071 102.611 54.2007 102.809 54.466C103.008 54.7977 106.919 52.1443 106.919 51.7131C106.919 51.2819 106.123 51.4146 105.262 52.0116Z" fill="black"/>
            <path d="M73.3488 53.007C73.2825 53.1728 73.2494 56.5559 73.2825 60.5691C73.3488 67.8327 73.9784 76.6551 74.4424 76.6551C75.0389 76.6551 75.0389 75.3948 74.6081 70.4529C74.343 67.5673 74.111 62.4264 74.111 58.9771C74.111 53.5045 73.9121 51.9788 73.3488 53.007Z" fill="black"/>
            <path d="M96.8113 57.7827C96.0823 58.3134 95.4526 58.9104 95.3863 59.0762C95.1875 59.6069 96.5462 59.441 97.0102 58.8772C97.2421 58.6119 97.8386 58.1475 98.3689 57.8822C98.866 57.6169 99.2968 57.252 99.2968 57.0862C99.2968 56.456 98.1369 56.7877 96.8113 57.7827Z" fill="black"/>
            <path d="M102.047 57.9486C100.689 58.7777 100.225 59.4079 100.954 59.4079C101.716 59.4079 103.936 58.0481 103.936 57.5837C103.936 56.9204 103.671 56.9536 102.047 57.9486Z" fill="black"/>
            <path d="M55.2216 59.2088C55.2216 59.5405 57.4088 61.3979 57.8064 61.3979C58.5355 61.3979 58.2373 60.8008 57.1105 59.9385C56.0832 59.1425 55.2216 58.844 55.2216 59.2088Z" fill="black"/>
            <path d="M12.3394 70.8511C11.2458 71.8461 10.815 72.4762 11.047 72.7084C11.2458 72.9074 11.9417 72.5094 13.0685 71.4812C14.0295 70.6189 14.7917 69.7897 14.7917 69.6239C14.7917 68.9937 13.8638 69.458 12.3394 70.8511Z" fill="black"/>
            <path d="M11.8755 74.8637C10.5168 76.0245 10.1522 76.6547 10.8482 76.6547C11.279 76.6547 14.129 74.2998 14.129 73.935C14.129 73.338 13.1679 73.736 11.8755 74.8637Z" fill="black"/>
            <path d="M6.10921 76.257C4.94934 77.6832 4.45225 78.8772 5.11504 78.5787C5.61213 78.3797 8.16385 75.4279 8.16385 75.0299C8.16385 74.2338 7.26909 74.7645 6.10921 76.257Z" fill="black"/>
            <path d="M68.0796 84.4491C66.5221 85.5768 66.2569 85.9416 66.6878 86.3728C66.986 86.6713 70.4988 83.9184 70.2668 83.5867C70.0017 83.1224 69.7366 83.2219 68.0796 84.4491Z" fill="black"/>
            <path d="M66.3564 89.4237C64.8983 90.5514 64.4343 91.2479 65.1634 91.2479C65.7268 91.2479 68.4442 89.1252 68.4773 88.6609C68.4773 87.9975 68.0134 88.1965 66.3564 89.4237Z" fill="black"/>
            <path d="M61.2198 89.756C60.1593 90.9832 59.9274 91.5802 60.5239 91.5802C60.9878 91.5802 62.8105 89.5238 62.8436 88.9931C62.8436 88.2966 62.2802 88.562 61.2198 89.756Z" fill="black"/>
            </svg>
    </>
  )
}

export default Cactus
 



 