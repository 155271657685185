import React from 'react'

const YoutubeSvg = () => {
  return (
    <svg width="48" height="34" viewBox="0 0 48 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19.2 24L31.656 16.8L19.2 9.6V24ZM46.944 5.208C47.256 6.336 47.472 7.848 47.616 9.768C47.784 11.688 47.856 13.344 47.856 14.784L48 16.8C48 22.056 47.616 25.92 46.944 28.392C46.344 30.552 44.952 31.944 42.792 32.544C41.664 32.856 39.6 33.072 36.432 33.216C33.312 33.384 30.456 33.456 27.816 33.456L24 33.6C13.944 33.6 7.68 33.216 5.208 32.544C3.048 31.944 1.656 30.552 1.056 28.392C0.744 27.264 0.528 25.752 0.384 23.832C0.216 21.912 0.144 20.256 0.144 18.816L0 16.8C0 11.544 0.384 7.68 1.056 5.208C1.656 3.048 3.048 1.656 5.208 1.056C6.336 0.744 8.4 0.528 11.568 0.384C14.688 0.216 17.544 0.144 20.184 0.144L24 0C34.056 0 40.32 0.384 42.792 1.056C44.952 1.656 46.344 3.048 46.944 5.208Z" fill="white"/>
    </svg>

  )
}

export default YoutubeSvg