import React from 'react'

const Penguin = () => {
  return (
    <>
        <svg width="39" height="56" viewBox="0 0 39 56" fill="none" 
          xmlns="http://www.w3.org/2000/svg" className='element-item' id='penguin'>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M27.5289 2.10066C21.2203 3.65635 16.2831 6.73464 13.6431 10.7066C12.7174 12.0968 12.4774 12.825 12.2716 15.0758C12.0316 17.79 12.0316 17.79 13.2659 19.776L14.5345 21.762L13.1288 23.5163C11.2088 25.9326 8.80875 30.699 7.9516 33.8766C7.43731 35.6971 7.19731 37.6169 7.16302 40.0001C7.12873 43.277 7.19731 43.608 8.22589 45.627C10.1459 49.3673 12.4774 51.3533 16.146 52.3463C18.5117 52.9752 19.7117 53.0414 27.1861 53.0414C35.1404 53.0745 38.1576 52.8759 38.1576 52.3132C38.1576 52.1477 37.0262 51.2209 35.6205 50.2279C33.4947 48.7384 33.049 48.275 32.9461 47.3482C32.8776 46.7855 32.9461 46.1566 33.0833 46.0242C33.4947 45.627 34.3862 39.1064 34.3862 36.3591C34.3862 35.002 34.1461 32.354 33.9061 30.5004C33.6661 28.6468 33.529 26.7932 33.5976 26.3629C33.7347 25.6678 32.1576 21.5634 30.2033 17.6245L29.5175 16.1681L31.9518 13.0236C33.289 11.3024 34.7633 9.64744 35.2433 9.34954C36.3062 8.68754 36.3405 7.89314 35.3462 7.89314C34.6262 7.86004 34.6262 7.82694 35.1747 7.52904C35.4833 7.36354 35.7576 6.96634 35.7576 6.66844C35.7576 6.23815 35.449 6.17195 33.8376 6.33745L31.9176 6.53604L34.1804 5.01345C36.9919 3.12675 36.7862 2.66335 33.3919 3.35845L30.9918 3.82185L31.6433 2.99435C33.2204 1.07456 32.4318 0.909059 27.5289 2.10066ZM30.7861 44.9655C30.889 45.131 30.8204 45.2965 30.649 45.2965C30.4432 45.2965 30.2718 45.131 30.2718 44.9655C30.2718 44.7669 30.3404 44.6345 30.409 44.6345C30.5118 44.6345 30.6833 44.7669 30.7861 44.9655Z" fill="#2E4153"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M21.9403 2.43097C21.5975 3.22537 20.7746 4.58246 20.1232 5.37686C18.9917 6.83326 18.6832 7.56146 19.2317 7.56146C20.0889 7.56146 23.4146 2.66267 23.4146 1.40487C23.4146 0.411874 22.5232 1.00767 21.9403 2.43097Z" fill="#FDE964"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M23.5175 5.47681C22.4204 6.33741 21.4603 7.06561 21.3918 7.13181C21.2889 7.19801 21.3918 7.39661 21.5632 7.5621C21.9746 7.9593 26.1575 5.01341 26.1575 4.35141C26.1575 3.62321 25.609 3.85491 23.5175 5.47681Z" fill="#FDE964"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M26.3289 7.39611C26.1575 7.46231 25.0946 7.76021 24.0318 8.05811C22.0432 8.58771 21.3575 9.21661 22.8318 9.21661C24.1004 9.18351 28.009 8.02501 28.1461 7.62781C28.249 7.26371 27.1518 7.13131 26.3289 7.39611Z" fill="#FDE964"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M18.8203 10.1768C18.4774 10.6733 18.9917 11.5008 19.506 11.3022C20.1232 11.0705 20.1232 9.87891 19.506 9.87891C19.2317 9.87891 18.9232 10.0113 18.8203 10.1768Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M12.786 23.5488C12.786 23.7805 12.2717 24.4756 11.6203 25.0714C10.0431 26.5278 8.46594 29.6392 7.60878 33.0154C6.61449 36.7557 6.78592 42.813 7.95165 45.7257C8.84308 47.9103 10.5231 49.797 11.9288 50.128C12.786 50.3266 13.0603 50.1942 13.8831 49.2343C15.1517 47.811 17.0032 44.0708 17.7575 41.5221C18.546 38.9072 18.546 33.6443 17.7575 30.566C17.4489 29.2751 16.6946 27.3884 16.1117 26.3292C14.8089 23.946 12.786 22.2579 12.786 23.5488Z" fill="white"/>
        <path d="M19.5403 10.0775C19.0603 10.5409 19.3003 11.236 19.9175 11.1367C20.226 11.0705 20.5003 10.8057 20.5003 10.574C20.5003 10.0444 19.8832 9.74646 19.5403 10.0775Z" fill="black"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.785858 12.6592C0.785858 13.2881 2.29445 14.248 6.95735 16.4988C12.1003 18.9813 11.9974 18.9813 12.9917 17.9221C13.6088 17.2932 13.8146 16.6974 13.8146 15.6382C13.8146 13.3543 13.1974 12.9902 8.98023 12.7254C7.02592 12.6261 4.38589 12.4275 3.11731 12.3282C1.09443 12.1296 0.785858 12.1958 0.785858 12.6592Z" fill="#DD211E"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M7.23162 52.2137C4.42016 53.2398 2.15728 54.2328 2.15728 54.4314C2.15728 54.6962 5.62018 54.8617 13.3688 55.0272C25.3347 55.292 25.6775 55.2258 25.2661 53.7032C25.0947 53.1074 24.7175 52.975 22.7975 52.7764C19.9517 52.5116 14.9117 51.3531 13.9174 50.7242C13.506 50.4594 12.9574 50.2939 12.7174 50.2939C12.4774 50.327 10.0088 51.1876 7.23162 52.2137Z" fill="#DD211E"/>
        <path d="M30.3404 3.0272C28.6947 4.61599 28.1461 5.57589 28.8318 5.57589C28.969 5.57589 29.929 4.74839 30.9575 3.7554C32.1918 2.5307 32.7061 1.8356 32.4661 1.6039C32.2261 1.3722 31.5404 1.8687 30.3404 3.0272Z" fill="black"/>
        <path d="M32.7404 5.2778C30.2376 7.06519 29.4833 7.89269 30.409 7.95889C30.8204 7.99199 36.4433 3.8876 36.4433 3.5566C36.4433 2.8946 35.449 3.358 32.7404 5.2778Z" fill="black"/>
        <path d="M32.9462 8.28982C31.7462 9.21662 30.7176 10.0441 30.649 10.1103C30.5461 10.1765 30.649 10.3751 30.8204 10.5406C31.0604 10.7723 31.9862 10.2758 33.6319 9.01802C35.0033 7.95882 36.1005 6.99893 36.1005 6.83343C36.1005 6.17143 34.9348 6.70103 32.9462 8.28982Z" fill="black"/>
        <path d="M33.5633 10.4082C31.8833 12.5927 31.4033 13.7843 32.329 13.4533C32.7747 13.2878 36.1005 8.78626 36.1005 8.32286C36.1005 7.42916 35.4148 7.99186 33.5633 10.4082Z" fill="black"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M7.47161 17.0281C1.98584 14.3801 0.168677 13.2216 0.58011 12.5927C0.75154 12.2948 1.26583 12.4603 2.26013 13.1554C3.04871 13.6519 5.62016 15.009 7.95161 16.1675C11.9288 18.0873 12.2717 18.1866 12.6831 17.6239C13.3688 16.7302 13.6088 14.8766 13.1631 13.9498C12.6145 12.7582 13.2317 12.2286 13.9517 13.2216C14.6374 14.1815 14.6717 15.8365 13.986 17.3591C13.5402 18.4183 13.5402 18.65 14.1231 19.7423C14.466 20.4043 14.8431 21.3973 14.9803 21.96C15.1174 22.4896 15.8374 24.1114 16.5917 25.5016C18.5117 29.1095 18.9574 30.7314 19.1974 34.7365C19.506 40.0656 18.4431 44.4679 16.0088 48.1751C14.3631 50.6576 13.746 50.8893 11.9631 49.6977C8.63733 47.4469 6.78589 43.3756 6.82017 38.3444C6.82017 33.3794 8.53448 28.7785 12.1002 24.3431C13.0259 23.1185 13.8145 21.8607 13.8145 21.4966C13.8145 20.3381 12.6488 19.5437 7.47161 17.0281ZM18.1003 36.0281C18.1003 31.0962 17.586 29.0109 15.5288 25.5023C14.946 24.5755 14.3631 23.7811 14.1917 23.7811C13.7117 23.7811 11.9631 26.065 10.6259 28.4151C6.51159 35.7633 6.88874 43.4756 11.5859 47.944C12.8545 49.1687 13.2659 49.3673 13.7459 49.0694C14.8431 48.4074 16.1803 46.1567 17.1403 43.3101C17.9974 40.7945 18.1003 40.0332 18.1003 36.0281Z" fill="black"/>
        <path d="M28.2147 16.1344C28.2147 16.2999 28.8318 17.326 29.5861 18.4183C32.5004 22.6551 33.8033 28.3814 33.8376 37.186C33.8719 43.0447 33.4947 45.6264 32.6033 45.6264C31.9519 45.6264 27.9061 41.5552 26.3289 39.3044C24.4432 36.5902 21.7003 31.0956 20.6717 27.9842C20.226 26.6602 19.746 25.7996 19.506 25.8658C18.9232 26.0644 19.1974 27.2229 20.5346 30.5329C22.3518 34.9683 25.2318 39.8671 27.6318 42.6475C29.7233 45.0637 32.7747 47.4138 33.4947 47.1821C33.7004 47.1159 34.1119 46.1229 34.4547 44.9313C35.929 39.503 34.5919 25.4686 32.1233 20.7022C30.4433 17.4584 28.2147 14.8435 28.2147 16.1344Z" fill="black"/>
        <path d="M24.7861 54.0672V54.9278L18.7174 54.663C10.0773 54.2989 1.47155 54.332 1.36869 54.7292C1.30012 55.0271 7.64304 55.325 20.6717 55.6229L25.6432 55.7222L25.7461 54.4644C25.8146 53.5376 25.7461 53.2397 25.3346 53.2397C24.9918 53.2397 24.7861 53.5376 24.7861 54.0672Z" fill="black"/>
        </svg>
    </>
  )
}

export default Penguin