import React from 'react'

const Menu = ({darkMode, setIsAbout}) => {
  
  const aux = darkMode ? 'hover:text-[#d44967]':'hover:text-[#22333F]';
  const css =  'roboto-mono text-white text-[32px] md:text-[40px] lg:text-[40px] xl:text-[48px] ' + aux;
  return ( 
    <ul className='flex flex-col'>
      <li className='mb-[5px]'>
       <a className={css} href='#'>Home</a>
      </li>
      <li className='mb-[5px]'>
       <a className={css} href='#'
         onClick={e => { e.preventDefault(); setIsAbout(true) }} > About Us
        </a>
      </li>
      <li className='mb-[5px]'>
       <a className={css} 
        target='_blank' href='http://medium.com/telluscoop'> Blog</a>
      </li>
       
      <li className='mb-[5px]'>
       <a className={css} 
        target='_blank' href='https://discord.gg/PxpDj9Cwm2'> Community</a>
      </li> 
    </ul>
  )
}

export default Menu