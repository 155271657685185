import React from 'react'
import Boat from './svg/map-elements/Boat'
import Surfer from './svg/map-elements/Surfer'
import Cascade from './svg/map-elements/Cascade'
import Friends from './svg/map-elements/Friends'
import Palms from './svg/map-elements/Palms'
import Cactus from './svg/map-elements/Cactus'
import Agricultor from './svg/map-elements/Agricultor'
import Alpacas from './svg/map-elements/Alpacas'
import Panels from './svg/map-elements/Panels'
import Molinete from './svg/map-elements/Molinete'
import Penguin from './svg/map-elements/Penguin'
import Pinos from './svg/map-elements/Pinos'
import Constructor from './svg/map-elements/Constructor'

const Elements = () => {
  return (
    <div className='elements absolute w-full h-full left-0 top-0'>
        <div className='relative w-full h-full left-0 top-0'>
            <Boat />
            <Surfer />
            <Cascade />
            <Friends />
            <Palms />
            <Cactus />
            <Agricultor />
            <Alpacas />
            <Panels />
            <Molinete />
            <Penguin />
            <Pinos />
            <Constructor />
        </div>
        
    </div>
  )
}

export default Elements;