import React from 'react'

const TwitterSvg = () => {
  return (
    <svg width="43" height="35" viewBox="0 0 43 35" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.5074 35C29.7345 35 38.6113 21.5311 38.6113 9.87134C38.6113 9.49444 38.6113 9.11215 38.5952 8.73525C40.3234 7.48201 41.815 5.93042 43 4.15318C41.3857 4.8662 39.6749 5.33614 37.9233 5.54772C39.7684 4.44296 41.1503 2.70471 41.8121 0.656062C40.0787 1.6848 38.1814 2.40697 36.2033 2.79095C34.8735 1.37224 33.1138 0.432391 31.1967 0.116976C29.2797 -0.198438 27.3123 0.128186 25.5993 1.04626C23.8864 1.96433 22.5234 3.42261 21.7216 5.19521C20.9198 6.96782 20.7238 8.95582 21.1641 10.8513C17.6562 10.6751 14.2244 9.76222 11.0914 8.17189C7.95841 6.58156 5.19409 4.34929 2.97775 1.61986C1.8526 3.56651 1.50923 5.86917 2.01736 8.06023C2.52549 10.2513 3.84703 12.1665 5.71362 13.4169C4.31489 13.3693 2.9469 12.9931 1.72 12.3185V12.4397C1.72241 14.4789 2.42764 16.4548 3.71647 18.0333C5.00531 19.6119 6.7987 20.6962 8.7935 21.1031C8.03633 21.312 7.25418 21.4162 6.46881 21.4126C5.91514 21.4143 5.36259 21.3629 4.81869 21.2592C5.38249 23.0146 6.4803 24.5495 7.95835 25.6487C9.4364 26.7479 11.2207 27.3565 13.0613 27.3893C9.93449 29.8494 6.07214 31.1836 2.09625 31.1771C1.39567 31.1801 0.695587 31.1396 0 31.056C4.03532 33.6331 8.72174 35.0015 13.5074 35Z" fill="white"/>
    </svg>

  )
}

export default TwitterSvg