import React from 'react'

const Close = ({setIsAbout, setOpenMenu, setActivePopup}) => {
  const handleClick = e  => {
    e.preventDefault();
    setActivePopup(false);
    setOpenMenu(false)
    setIsAbout(false); 
  }
  return (
    <button 
      className='m-0 p-0 outline-none bg-transparent svg-button'
      onClick={handleClick}>
      <svg width="24" className='block max-w-full h-auto' height="24" viewBox="0 0 24 24" 
        fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3.08569 3.08569L20.9143 20.9143" stroke="white" strokeWidth="5" strokeLinecap="round" className='stroke' />
        <path d="M3.08569 20.9143L20.9143 3.08573" stroke="white" strokeWidth="5" strokeLinecap="round" className='stroke' />
    </svg> 
    </button>
    
  )
}

export default Close