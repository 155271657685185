import React from 'react'

const Molinete = () => {
  return (
    <>
    <svg width="69" height="109" viewBox="0 0 69 109" fill="none" 
        xmlns="http://www.w3.org/2000/svg" className='element-item' id='molinete'>
        <path d="M25.9053 62.7028C25.6716 97.1716 25.705 99.7484 26.5728 98.8783C26.7063 98.778 26.8732 82.5475 26.9734 62.8367C27.1069 31.2459 27.0735 27.0293 26.6396 27.0293C26.2057 27.0293 26.1055 31.4132 25.9053 62.7028Z" fill="black"/>
        <ellipse cx="47.2334" cy="67.6895" rx="0.50066" ry="31.2896" fill="black"/>
        <path className='animate' fillRule="evenodd" clipRule="evenodd" d="M33.5153 1.3955C32.247 1.96441 29.6436 4.87585 27.9413 7.6869C25.8719 11.1338 25.2044 13.7775 25.2044 18.6634C25.2044 20.9724 25.3045 23.5158 25.4714 24.2855L25.7384 25.7579L24.6369 25.4567C24.0362 25.3229 21.6664 25.189 19.3633 25.2225C14.3901 25.2225 10.9522 26.0256 6.84682 28.067C3.70935 29.6064 1.00578 31.5139 1.00578 32.1832C1.00578 32.9194 3.00842 33.9903 5.5451 34.5926C7.28073 34.9942 8.78271 35.0946 11.5196 34.9608C17.4942 34.6596 21.366 33.254 24.6036 30.2422L26.4727 28.502L27.9079 31.3466C30.1442 35.8643 35.2176 40.9175 39.0893 42.5238L40.3243 43.0258L39.1895 45.9038C38.4885 47.577 37.9545 49.6518 37.821 50.9235C37.6207 52.9983 37.6541 53.1322 38.3217 53.1322C39.0226 53.1322 41.5593 51.2916 43.5285 49.2837C45.7314 47.1085 47.7341 42.7581 48.2347 39.1773L48.4684 37.4706L49.67 37.9726C51.3055 38.6754 56.4122 38.9096 59.4162 38.4411C63.3213 37.8387 67.7605 35.4962 67.7605 34.0572C67.7605 33.0867 64.5229 32.0159 60.9181 31.8151C57.1465 31.6143 53.742 32.2836 50.6713 33.823L48.4684 34.9608L48.1012 32.9194C47.2334 28.0335 43.4952 22.9803 38.9892 20.6713C36.0854 19.1653 35.8184 19.3661 36.3858 22.6792C36.8864 25.5571 39.056 30.1418 41.0586 32.4509C41.7929 33.321 43.1614 34.5257 44.0626 35.0946C44.9971 35.697 45.7314 36.2659 45.6981 36.3663C45.6981 36.4667 45.0305 37.136 44.2295 37.8387L42.8276 39.0769L41.5259 36.8348C38.9892 32.4174 33.6488 28.0335 29.6436 27.0296C27.9079 26.5945 27.741 26.3603 28.6089 25.6575C29.6769 24.754 32.3471 20.2697 33.3151 17.7264C34.9172 13.5432 35.5513 10.2637 35.5513 5.87979C35.5513 1.29511 35.2843 0.659276 33.5153 1.3955Z" fill="#CAD9E4"/>
        <path className='animate' fillRule="evenodd" clipRule="evenodd" d="M30.6448 41.6539C29.1428 45.1677 29.5434 51.4926 31.546 55.0733C32.5473 56.8804 32.5473 56.8135 31.6461 56.8135C29.5767 56.8135 23.7691 60.4612 20.7985 63.6069C18.2618 66.284 18.3953 66.6522 21.6329 66.4179C26.239 66.1167 29.9105 64.0419 32.9812 60.093L34.5166 58.0851L35.5513 59.3903C37.9211 62.4356 40.7582 64.4769 44.3963 65.7151C46.8662 66.5852 50.0704 67.1207 50.0704 66.6856C50.0704 65.9494 47.467 62.3352 45.8649 60.8627C43.662 58.8214 39.8236 56.8804 37.5539 56.6127L35.8851 56.4119V52.1619C35.8851 48.715 35.7515 47.5103 35.1508 45.6028C34.4165 43.3271 32.3804 40.0811 31.7129 40.0811C31.5126 40.0811 31.0453 40.7838 30.6448 41.6539Z" fill="#ACBFCD"/>
        <path d="M33.7824 77.1263C33.8491 89.9768 33.9826 96.8036 34.2163 96.8036C34.4499 96.8036 34.5834 89.9768 34.6502 77.1263C34.7169 59.758 34.6502 57.4824 34.2163 57.4824C33.7824 57.4824 33.7156 59.758 33.7824 77.1263Z" fill="black"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M32.8811 94.4279C31.2122 94.9633 28.8091 96.2015 28.5421 96.6366C28.4419 96.8039 27.3405 96.9712 26.1055 96.9712C21.6329 96.9712 17.8279 98.544 14.6571 101.69C12.7212 103.564 10.3848 107.546 10.9188 108.015C11.0523 108.182 18.0616 108.383 26.4727 108.517L41.7261 108.784V108.082C41.7261 107.713 41.5592 106.977 41.3923 106.475C41.1921 105.973 41.0919 105.538 41.1253 105.505C41.1921 105.471 44.5298 105.605 48.5685 105.873C52.6071 106.107 56.7793 106.308 57.8474 106.308L59.7499 106.341V105.237C59.7499 103.396 57.8474 100.82 55.1772 98.9456C52.24 96.9043 46.6993 95.4987 44.2962 96.2015C43.5285 96.4358 42.8609 96.2684 40.9918 95.3314C38.3216 93.9928 35.2175 93.6247 32.8811 94.4279Z" fill="#FEBB64"/>
        <path d="M34.0494 0.659084C34.0494 0.726014 34.283 1.5961 34.55 2.60005C35.1842 5.00952 35.1842 10.0627 34.5167 13.0745C34.2496 14.4131 33.2483 17.0234 32.3471 18.8974C30.9453 21.8423 30.211 22.8463 26.8732 26.4605C21.9334 31.8483 19.4635 33.555 14.7906 34.7598C10.3181 35.8976 6.37955 35.3621 3.10857 33.2204C2.14063 32.5846 1.20606 32.0491 1.0058 32.0491C-0.362675 32.0491 2.4744 34.4586 5.01108 35.4625C6.51306 36.0649 7.51438 36.1988 10.6852 36.1988C13.9896 36.1988 14.824 36.0984 16.7599 35.3287C19.6637 34.2243 22.2672 32.6515 24.5702 30.6101C25.8385 29.4389 26.3726 29.1377 26.3726 29.5393C26.3726 30.7775 28.6756 34.3582 31.0454 36.8681C35.2176 41.2185 39.7903 44.0965 42.6607 44.0965C44.63 44.0965 44.363 43.3268 42.2602 43.0256C36.9532 42.2559 27.3739 33.0196 27.3739 28.6022C27.3739 27.7322 27.7077 27.1298 29.0428 25.7577C32.1802 22.5116 34.3831 18.2281 35.5514 13.2753C36.6528 8.62372 36.0854 0.926803 34.6502 0.659084C34.3164 0.592154 34.0494 0.592154 34.0494 0.659084Z" fill="black"/>
        <path d="M35.8852 20.0016C35.5514 20.537 35.9185 20.8048 37.2203 20.9052C42.594 21.2398 48.9691 30.9111 47.1 35.8305C46.8329 36.4998 46.8997 36.801 47.4337 37.236C47.9344 37.7045 48.0345 38.1061 47.8343 39.2104C47.0666 44.3975 42.2602 51.4251 38.789 52.496C38.0213 52.7302 37.3871 53.0984 37.3871 53.3661C37.3871 54.5373 40.1575 53.3661 42.4271 51.2243C45.598 48.2794 48.168 43.5609 48.9357 39.3108C49.0358 38.6415 49.2027 38.0726 49.2695 38.0726C49.3029 38.0726 50.2374 38.3069 51.3055 38.5746C53.742 39.2104 57.981 39.2104 60.7179 38.5746C64.356 37.7045 69.2291 34.7596 68.6283 33.7557C68.4948 33.5549 67.7939 33.8561 66.826 34.5254C63.021 37.236 59.5497 38.24 54.9103 37.9722C53.2748 37.8718 51.0719 37.5037 50.0705 37.1356L48.2348 36.4998L48.3349 34.1907C48.6353 27.9997 43.128 20.7044 37.5207 19.8008C36.5861 19.667 36.052 19.7339 35.8852 20.0016Z" fill="black"/>
        <path d="M31.1789 39.7798C31.0788 39.9806 31.2791 40.4491 31.6462 40.8507C34.5167 43.9964 35.8184 51.2248 34.4833 56.4118C33.9159 58.4867 33.6822 58.8548 31.1789 61.3646C27.5074 65.0792 25.5382 66.0163 21.633 66.0163C19.5302 66.0163 18.6958 66.1501 18.6958 66.4178C18.6958 67.0537 20.1644 67.2879 22.7011 67.1206C26.0388 66.8529 28.2417 65.6816 31.7797 62.2013L34.6836 59.3233L36.1522 60.9965C36.9532 61.9001 38.5887 63.2387 39.7569 63.9414C43.8289 66.3174 50.0705 68.2584 50.0705 67.1206C50.0705 66.8194 49.2361 66.4513 47.6673 66.0832C44.9638 65.4474 40.6247 63.4729 38.4886 61.8666C37.6875 61.2977 36.6528 60.2268 36.1855 59.4906C35.2843 58.152 35.2843 58.0851 35.7183 55.8429C36.6194 50.9571 35.5847 44.7326 33.3151 41.3861C32.1469 39.6794 31.5461 39.2109 31.1789 39.7798Z" fill="black"/>
        <path d="M31.2123 94.2605C29.7437 94.9298 28.4753 96.168 28.8425 96.5361C28.976 96.67 29.3432 96.6031 29.6436 96.3688C32.0801 94.5952 35.8184 94.0932 38.9558 95.1641C41.3256 95.9672 41.5926 96.2684 40.4912 96.9712C38.9558 97.9417 37.8878 99.2803 38.2883 99.6818C38.5553 99.9496 38.9892 99.7488 39.7903 98.9121C42.3603 96.3353 47.5004 96.3019 52.6406 98.8452C54.9102 99.9496 58.1478 103.095 58.7486 104.735C59.2159 106.007 60.0837 106.475 60.0837 105.505C60.0837 103.396 56.5457 99.6149 52.9743 97.8413C50.0371 96.4023 48.3015 96.0007 45.1306 95.9672C43.1948 95.9672 42.4938 95.8334 41.8263 95.2979C39.6901 93.6247 33.8157 93.0558 31.2123 94.2605Z" fill="black"/>
        <path d="M23.0348 97.0044C20.0976 97.5064 16.593 99.3804 14.3901 101.589C11.7199 104.233 9.68389 108.349 10.9856 108.349C11.1525 108.349 11.7199 107.412 12.2873 106.241C13.6558 103.463 16.593 100.652 19.597 99.2131C22.6677 97.7741 27.1402 97.5399 30.6449 98.6107C34.7503 99.8489 38.2215 102.693 40.4578 106.575C41.4258 108.316 41.7261 108.583 42.1601 108.148C42.5272 107.747 40.7582 104.768 38.9892 102.827C34.8504 98.2426 28.709 96.0339 23.0348 97.0044Z" fill="black"/>
        </svg>
    </>
  )
}

export default Molinete