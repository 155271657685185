
import React from 'react'

const Pinos = () => {
  return (
    <>
        <svg width="72" height="295" viewBox="0 0 72 295" fill="none" 
        xmlns="http://www.w3.org/2000/svg" className='element-item' id='pinos'>
        <path fillRule="evenodd" clipRule="evenodd" d="M7.94231 237.638C4.18494 242.519 0.527328 247.932 0.527328 248.629C0.527328 250.389 7.84256 252.116 11.8327 251.252C12.8302 251.053 14.4928 250.455 15.5901 249.991C17.9176 248.961 17.9176 249.426 15.3905 243.349C12.5975 236.675 11.4337 234.284 10.9682 234.284C10.7022 234.284 9.33886 235.778 7.94231 237.638Z" fill="#468C45"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M36.638 179.326C31.5839 186.532 27.4607 192.642 27.4607 192.908C27.4607 194.767 34.9755 197.723 42.3904 198.785C46.3473 199.35 53.5295 199.018 56.7216 198.088C60.9445 196.893 66.1649 194.037 67.0295 192.476C67.3952 191.779 68.1268 192.775 51.9667 172.718C49.0739 169.132 46.5136 166.209 46.2808 166.209C46.0148 166.209 41.6922 172.12 36.638 179.326Z" fill="#468C45"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M40.2623 94.0511C38.1675 96.7408 26.6294 113.909 24.4016 117.728C22.1073 121.646 20.4115 125 20.5445 125.365C20.611 125.498 21.542 126.361 22.606 127.291C25.0666 129.35 28.5247 131.143 31.9828 132.04C35.3744 132.936 42.1244 133.235 45.6822 132.671C51.9002 131.674 59.3817 127.955 59.3817 125.83C59.3817 125.398 57.9519 122.31 56.1896 118.956C52.6317 112.282 48.0098 102.984 45.782 98.1355C44.984 96.4088 44.0197 94.3499 43.6872 93.553C42.7894 91.627 42.0911 91.7266 40.2623 94.0511Z" fill="#468C45"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M22.2736 4.89143C20.9768 6.95026 18.1837 11.4 16.0224 14.7871C6.74535 29.3981 1.19243 38.53 1.19243 39.2606C1.19243 40.2235 2.12346 40.6884 6.54585 42.0167C18.084 45.437 27.4275 45.9019 36.9041 43.4778C39.4644 42.8137 44.3523 40.8545 45.4163 40.0575C46.3474 39.327 46.3141 39.1941 43.9865 35.5414C43.2883 34.4456 40.6614 29.4645 38.1011 24.4171C31.1184 10.5698 25.9977 1.3383 25.2662 1.23868C24.8007 1.20547 24.0359 2.10206 22.2736 4.89143Z" fill="#468C45"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M38.0678 34.2786C31.4841 43.643 30.1208 45.7018 30.1208 46.3327C30.1208 47.0965 32.3153 48.1923 35.2747 48.8565C38.8658 49.6866 45.6157 49.6866 49.1071 48.8897C51.6674 48.2919 54.7598 46.698 56.4223 45.1373C57.6194 44.0083 57.3534 43.1117 55.0923 40.6212C52.8977 38.1971 48.4753 32.7511 46.6465 30.2938C44.984 28.0358 44.0529 27.0728 43.5874 27.0728C43.3547 27.0728 40.8941 30.327 38.0678 34.2786Z" fill="#2E4153"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M53.4963 117.628C50.1047 121.646 49.2069 122.742 44.5518 128.52C39.8966 134.331 39.7969 134.597 42.0247 135.527C47.4446 137.785 57.1207 138.25 64.0369 136.589C65.6662 136.191 69.3903 134.398 70.6538 133.402L71.4519 132.737L68.7918 129.151C67.362 127.159 65.4999 124.569 64.7019 123.373C61.7758 119.023 57.7192 113.743 57.2537 113.743C56.9877 113.743 55.2919 115.503 53.4963 117.628Z" fill="#2E4153"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M33.3129 143.861C32.6478 144.559 30.8855 146.85 29.3892 148.942C27.8929 151.034 25.3326 154.521 23.7365 156.679C22.1405 158.838 20.8105 160.93 20.8105 161.328C20.8105 163.088 29.9545 165.546 36.5382 165.546C42.2906 165.546 49.4064 163.686 49.4064 162.225C49.4064 162.092 48.7746 161.162 47.9766 160.199C43.7204 154.919 41.7254 152.296 38.5665 147.68C36.638 144.924 34.9422 142.633 34.7759 142.633C34.6096 142.633 33.9446 143.197 33.3129 143.861Z" fill="#2E4153"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M23.9361 179.592C20.079 184.54 13.4953 193.506 12.3647 195.366C11.6332 196.561 16.0224 198.819 21.2095 199.882C24.4681 200.546 31.8499 200.579 35.2082 199.948C38.4668 199.317 42.6565 197.557 43.2882 196.561C43.787 195.831 43.7537 195.698 42.6565 194.37C41.1269 192.51 35.5407 183.511 32.4151 177.899C30.5198 174.479 29.8215 173.516 29.2895 173.516C28.824 173.516 27.2612 175.309 23.9361 179.592Z" fill="#2E4153"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M17.0864 241.158C10.2367 250.921 5.84755 258.259 5.84755 259.92C5.84755 260.684 6.11356 260.982 7.47685 261.68C11.866 263.871 18.7822 264.702 24.7674 263.705C29.9878 262.809 33.5457 261.414 37.0038 258.824C39.4976 256.964 39.5641 256.333 37.5358 253.976C36.638 252.913 33.9114 249.592 31.4841 246.571C24.9669 238.468 22.1738 235.28 21.6751 235.28C21.409 235.28 19.3475 237.937 17.0864 241.158Z" fill="#2E4153"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M33.4127 280.342C31.1183 282.832 26.1307 290.47 26.1307 291.466C26.1307 292.263 27.1615 292.961 29.4558 293.724C32.6479 294.82 36.5715 294.588 39.4643 293.16C41.8584 291.964 43.7537 290.304 43.7537 289.341C43.7537 288.976 41.8584 286.817 39.1318 284.094C36.6048 281.537 34.4434 279.445 34.3769 279.445C34.3104 279.445 33.8449 279.844 33.4127 280.342Z" fill="#2E4153"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M18.1504 57.6897C9.10607 70.5407 4.51743 77.3814 4.51743 77.9791C4.51743 78.9421 5.88072 80.0047 9.20582 81.665C13.861 83.9563 16.7206 84.6205 22.1405 84.6537C25.4988 84.6537 27.2279 84.5208 28.9569 84.0559C33.1798 82.9601 39.431 79.6726 39.431 78.5768C39.431 78.3112 38.9323 77.5142 38.3337 76.7504C36.8374 74.8908 32.3153 67.1869 27.6269 58.5199C24.9336 53.5388 23.5703 51.314 23.1713 51.314C22.8388 51.314 20.9434 53.7049 18.1504 57.6897Z" fill="#468C45"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M10.0039 98.7997C6.61229 103.05 2.52242 109.061 2.52242 109.758C2.52242 110.356 5.44851 112.248 7.57657 112.979C8.4411 113.311 10.2699 113.51 12.1652 113.544C15.7563 113.577 17.9177 112.979 20.1455 111.385C21.3758 110.489 21.5088 110.289 21.276 109.359C21.1763 108.795 19.6467 105.906 17.8844 102.984C16.1553 100.028 14.526 97.1393 14.26 96.5416C13.994 95.9107 13.595 95.479 13.2293 95.479C12.8635 95.479 11.5667 96.8073 10.0039 98.7997Z" fill="#2E4153"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M25.3659 205.361C22.872 208.382 13.994 220.536 13.2625 221.931L12.664 223.06L13.8278 224.023C15.2908 225.285 18.4497 226.779 20.8105 227.344C23.9361 228.107 28.8572 227.875 32.0161 226.845C35.0419 225.882 37.4693 224.255 37.8018 222.993C38.0678 221.898 28.6577 203.069 27.7932 203.069C27.4939 203.069 26.3967 204.099 25.3659 205.361Z" fill="#468C45"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M27.0617 268.387C26.0975 269.55 21.0765 277.519 19.946 279.645C18.483 282.5 18.483 282.899 20.2453 283.729C22.5063 284.792 24.6344 285.19 27.5605 285.024C29.7551 284.924 30.6861 284.692 32.2489 283.895C33.3129 283.364 34.244 282.766 34.3437 282.567C34.4767 282.201 30.2871 271.409 29.0901 269.052C28.3918 267.69 27.8265 267.524 27.0617 268.387Z" fill="#468C45"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M24.8006 1.2386C23.5038 2.73291 11.999 20.8307 10.5359 23.6864C9.90414 24.9151 9.90414 25.1143 10.3364 25.2804C10.9349 25.5128 13.1627 23.7197 16.5543 20.1665C18.9817 17.6096 19.1147 17.5432 19.3142 18.3401C19.7464 20.1665 21.4423 23.4208 21.941 23.4208C22.207 23.4208 23.5038 21.7937 24.8006 19.768L27.1947 16.1153L29.1565 17.6428C30.2538 18.473 32.3486 20.1997 33.8116 21.4616C36.3387 23.62 37.037 24.0185 37.5358 23.5536C37.6355 23.4208 35.2082 18.2737 32.0826 12.0972C26.2969 0.574463 25.8979 0.00994664 24.8006 1.2386Z" fill="white"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M40.3621 34.0463C39.3646 35.8063 39.2316 36.3708 39.7636 36.3708C40.1626 36.3708 42.0912 33.4154 42.0912 32.7844C42.0912 31.8546 41.1934 32.5188 40.3621 34.0463Z" fill="white"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M40.7944 37.9974C39.9631 39.1265 39.8966 39.691 40.5949 39.691C41.0604 39.691 42.0912 38.097 42.0912 37.3665C42.0912 36.7356 41.4926 37.0344 40.7944 37.9974Z" fill="white"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M35.6737 41.1197C34.6762 42.5808 34.5099 43.0789 35.0419 43.2781C35.3412 43.3777 37.436 40.4888 37.436 39.9574C37.436 39.2601 36.505 39.8578 35.6737 41.1197Z" fill="white"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M18.5494 61.575C18.1504 62.2723 17.5851 63.0361 17.3191 63.2685C16.6874 63.7998 16.6541 64.5968 17.2859 64.5968C17.7514 64.5968 20.1455 61.3093 20.1455 60.6452C20.1455 59.815 19.2144 60.3795 18.5494 61.575Z" fill="white"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M17.6184 67.6181C16.4547 69.4777 17.0532 70.1418 18.2835 68.3819C18.749 67.6845 19.148 66.9208 19.148 66.6883C19.148 65.8581 18.483 66.2898 17.6184 67.6181Z" fill="white"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M12.0654 70.7402C10.4361 72.965 10.2034 73.5628 10.9349 73.5628C11.5667 73.5296 14.6258 69.1463 14.26 68.781C13.861 68.3825 13.595 68.6149 12.0654 70.7402Z" fill="white"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M39.2315 95.2128C35.7402 99.5961 29.3892 109.027 29.5555 109.525C29.622 109.724 29.8215 109.923 29.9877 109.99C30.4533 110.156 35.0086 107.167 36.7377 105.573C37.5025 104.909 38.1675 104.411 38.2008 104.478C38.2673 104.544 38.633 106.071 39.0653 107.865C39.697 110.455 39.9298 111.086 40.3953 111.019C40.6946 110.953 41.7254 109.392 42.7561 107.367L44.6182 103.78L47.0123 105.607C50.7031 108.363 51.3017 107.964 49.2068 104.112C48.5751 102.917 47.0788 99.8618 45.8817 97.3049C43.8534 92.9548 42.9889 91.4937 42.4236 91.4937C42.2906 91.4937 40.8608 93.154 39.2315 95.2128Z" fill="white"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M10.4362 101.456C9.8044 102.352 9.63815 102.817 9.90415 102.983C10.3364 103.249 12.1652 101.257 12.1652 100.559C12.1652 99.7291 11.3339 100.161 10.4362 101.456Z" fill="white"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M10.8352 105.441C10.0372 106.969 10.0372 107.101 10.6024 107.101C10.8352 107.101 11.3672 106.537 11.7662 105.806C12.6308 104.279 12.664 104.113 11.999 104.113C11.733 104.113 11.201 104.711 10.8352 105.441Z" fill="white"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M6.87829 106.968C5.781 108.463 5.7145 109.027 6.67878 108.662C7.24405 108.429 8.50759 106.503 8.50759 105.839C8.50759 105.076 7.94232 105.441 6.87829 106.968Z" fill="white"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M52.4988 121.679C50.936 123.472 50.8362 123.705 51.5345 123.705C52.133 123.705 54.3941 121.214 54.3941 120.55C54.3941 119.72 53.9618 119.986 52.4988 121.679Z" fill="white"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M53.0972 126.959C51.5677 129.217 51.5344 129.35 52.2327 129.35C52.931 129.35 55.2253 125.731 54.793 125.299C54.5603 125.067 53.995 125.631 53.0972 126.959Z" fill="white"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M46.7795 129.881C45.8485 131.11 45.4495 131.84 45.6822 132.073C46.0147 132.405 49.0738 129.117 49.0738 128.387C49.0738 127.557 48.1761 128.154 46.7795 129.881Z" fill="white"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M31.1848 150.271C29.7218 152.296 29.489 152.927 30.2206 152.927C30.6861 152.894 33.1134 149.441 33.1134 148.743C33.1134 147.847 32.5814 148.278 31.1848 150.271Z" fill="white"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M32.3818 155.218C31.7168 156.613 31.6171 157.244 32.0826 157.244C32.4483 157.244 33.7784 155.052 33.7784 154.388C33.7784 153.492 32.9471 153.957 32.3818 155.218Z" fill="white"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M26.3635 157.775C25.0667 159.601 24.9669 160.166 25.9644 159.801C26.4632 159.601 28.4583 156.712 28.4583 156.181C28.4583 155.417 27.494 156.148 26.3635 157.775Z" fill="white"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M45.8817 166.143C45.4827 166.409 37.436 177.965 37.436 178.297C37.436 179.06 38.9988 178.097 41.4594 175.839L44.3189 173.216L44.9507 175.607C45.3497 177.068 45.7487 177.998 46.0147 177.998C46.2808 177.998 46.7795 177.101 47.1453 176.005C47.511 174.91 47.9433 173.88 48.0763 173.747C48.2093 173.581 49.7389 174.378 51.4347 175.474C53.2967 176.67 54.6933 177.334 54.8595 177.168C55.1588 176.869 53.5627 174.644 48.8078 168.7C46.5135 165.844 46.4138 165.778 45.8817 166.143Z" fill="white"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M25.9644 176.869C21.9743 182.115 21.9743 182.115 22.4398 182.58C22.6725 182.813 23.3043 182.58 24.6344 181.717C25.6319 181.02 26.4632 180.555 26.4632 180.621C26.4632 180.721 26.0974 182.149 25.6319 183.842C25.0001 186.2 24.9004 186.997 25.1996 187.196C25.7316 187.495 26.4299 186.864 28.691 183.909L30.553 181.484L31.4176 182.016C31.8831 182.348 32.8806 183.078 33.6454 183.709C35.1417 184.905 35.707 185.071 35.973 184.307C36.2057 183.709 30.2205 172.519 29.6885 172.519C29.4558 172.519 27.7932 174.478 25.9644 176.869Z" fill="white"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M21.3425 187.262C20.1455 188.723 19.8462 189.454 20.4448 189.454C20.877 189.454 23.1381 186.764 23.1381 186.233C23.1381 185.436 22.5728 185.768 21.3425 187.262Z" fill="white"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M22.3401 190.384C21.4423 191.679 21.2428 192.443 21.7748 192.443C22.2403 192.443 23.8031 190.318 23.8031 189.687C23.8031 188.79 23.3043 189.023 22.3401 190.384Z" fill="white"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M16.5211 193.738C15.4238 195.099 15.2243 195.763 15.9226 195.763C16.2883 195.73 18.4829 193.107 18.4829 192.675C18.4829 192.011 17.4189 192.609 16.5211 193.738Z" fill="white"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M23.6701 210.839C22.3401 212.433 22.2403 212.699 22.9386 212.699C23.5039 212.699 25.4657 210.441 25.4657 209.81C25.4657 209.013 24.8672 209.378 23.6701 210.839Z" fill="white"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M22.9718 216.518C21.7748 218.012 21.6418 218.61 22.606 218.245C23.4373 217.912 24.9336 215.688 24.5679 215.322C24.3351 215.09 23.8363 215.522 22.9718 216.518Z" fill="white"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M18.3831 218.477C17.1861 219.905 16.7871 221.001 17.4854 221.001C17.9176 221.001 20.478 217.614 20.2785 217.282C19.9792 216.784 19.6134 217.016 18.3831 218.477Z" fill="white"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M21.3093 234.882C19.8795 236.11 13.3623 245.773 13.6615 246.238C14.0273 246.803 14.3266 246.637 17.0531 244.279L19.7132 241.955L20.478 244.512C21.7415 248.928 22.0075 249.692 22.4066 249.825C22.9386 249.991 23.3708 248.795 24.0026 245.242L24.5679 242.254L26.4299 243.316C27.4607 243.881 28.5248 244.545 28.824 244.81C29.09 245.043 29.5223 245.242 29.7218 245.242C30.6196 245.242 29.9878 243.781 28.0592 241.39C26.8955 239.995 25.1664 237.87 24.1689 236.642C23.2046 235.446 22.3068 234.417 22.1738 234.383C22.074 234.35 21.675 234.55 21.3093 234.882Z" fill="white"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M8.14187 240.162C6.71208 242.154 6.67883 242.254 7.27734 242.254C7.90911 242.254 9.83768 239.863 9.83768 239.132C9.83768 238.236 9.33891 238.535 8.14187 240.162Z" fill="white"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M8.60734 243.914C7.37705 245.342 7.31055 245.574 7.97557 245.574C8.57409 245.574 10.5359 243.25 10.3031 242.851C10.0039 242.386 9.70462 242.552 8.60734 243.914Z" fill="white"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M4.11845 245.707C3.15417 247.201 2.95466 247.898 3.51993 247.898C3.88569 247.898 5.8475 245.109 5.8475 244.611C5.8475 243.781 4.94972 244.345 4.11845 245.707Z" fill="white"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M25.4656 273.135C24.6011 274.53 24.5346 274.862 25.0666 275.061C25.3991 275.161 27.1282 272.704 27.1282 272.139C27.1282 271.375 26.2304 271.907 25.4656 273.135Z" fill="white"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M26.5297 276.191C25.7316 277.718 25.5986 278.449 26.1307 278.449C26.4632 278.449 28.1257 275.759 28.1257 275.195C28.1257 274.331 27.2612 274.896 26.5297 276.191Z" fill="white"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M22.4065 278.283C21.675 279.246 21.6418 279.777 22.2735 279.777C22.7391 279.777 23.8031 278.415 23.8031 277.784C23.8031 277.187 22.9718 277.452 22.4065 278.283Z" fill="white"/>
        <path d="M25.7982 0.474155C25.7982 1.07188 40.3621 30.5264 40.6947 30.6592C40.9274 30.7256 41.0937 30.5264 41.0937 30.2275C41.0937 29.9287 38.6331 24.6488 35.6405 18.5055C27.6602 2.06809 26.696 0.175293 26.2305 0.175293C25.9977 0.175293 25.7982 0.30812 25.7982 0.474155Z" fill="black"/>
        <path d="M43.4212 27.4712C43.4212 28.1022 56.3226 44.4732 56.9211 44.606C57.2204 44.6724 57.3867 44.5396 57.3202 44.2739C57.1207 43.4769 44.2857 27.106 43.8535 27.0728C43.6207 27.0728 43.4212 27.272 43.4212 27.4712Z" fill="black"/>
        <path d="M23.0383 51.2145C22.7723 51.6462 38.633 78.3778 39.2316 78.4774C39.5308 78.5438 39.7636 78.4774 39.7636 78.3114C39.7636 77.8133 23.9361 51.1813 23.5703 51.0817C23.3708 51.0153 23.1381 51.0817 23.0383 51.2145Z" fill="black"/>
        <path d="M42.7562 91.3281C42.7562 91.627 53.7955 115.171 54.1613 115.636C54.3276 115.868 54.5936 116.067 54.7598 116.067C54.9261 116.067 55.0591 115.868 55.0591 115.636C55.0591 115.436 52.532 109.858 49.4729 103.283C45.7488 95.3462 43.6872 91.2949 43.3215 91.2285C43.0222 91.1621 42.7562 91.1953 42.7562 91.3281Z" fill="black"/>
        <path d="M13.4953 95.0468C13.4953 95.8438 21.2761 110.853 21.7416 111.019C22.6394 111.318 21.9078 109.492 18.1505 102.286C14.4263 95.08 13.4953 93.6189 13.4953 95.0468Z" fill="black"/>
        <path d="M57.2536 113.71C57.1538 113.876 58.3509 115.868 59.9469 118.093C61.543 120.351 64.4691 124.535 66.4641 127.358C69.7227 132.007 71.0195 133.435 71.0195 132.339C71.0195 132.007 68.4924 128.354 58.9494 114.805C57.9186 113.411 57.5861 113.178 57.2536 113.71Z" fill="black"/>
        <path d="M34.4434 142.898C34.4434 143.064 35.4409 144.592 36.6712 146.319C37.9015 148.045 40.4951 151.764 42.4236 154.587C47.0123 161.328 48.1761 162.856 48.5418 162.756C49.2734 162.557 49.0074 161.859 47.3115 159.701C46.3473 158.439 44.585 155.982 43.4212 154.255C37.702 145.654 34.4434 141.537 34.4434 142.898Z" fill="black"/>
        <path d="M45.7488 166.01C45.7488 166.276 46.7796 167.737 48.0099 169.264C50.2044 171.954 56.7881 180.09 63.4716 188.358C66.6637 192.343 67.362 192.974 67.362 191.945C67.362 191.38 46.7796 165.844 46.181 165.612C45.9483 165.545 45.7488 165.711 45.7488 166.01Z" fill="black"/>
        <path d="M29.7882 172.253C29.7882 172.884 42.9556 196.229 43.3879 196.362C44.3189 196.66 43.3214 194.568 37.2032 183.643C31.3843 173.216 29.7882 170.792 29.7882 172.253Z" fill="black"/>
        <path d="M27.7933 202.87C27.7933 203.402 36.9706 222.628 37.4693 223.126C37.6688 223.326 37.8683 223.326 38.0678 223.126C38.2674 222.96 37.3031 220.603 35.5408 217.016C33.978 213.828 31.7501 209.213 30.5864 206.789C28.6911 202.837 27.7933 201.575 27.7933 202.87Z" fill="black"/>
        <path d="M21.808 234.118C21.808 234.417 25.2328 239.032 29.4557 244.346C39.4643 257.064 38.6663 256.101 39.0985 255.669C39.3313 255.403 37.1035 252.315 30.9853 244.545C26.3301 238.634 22.34 233.753 22.1737 233.686C21.9742 233.62 21.808 233.819 21.808 234.118Z" fill="black"/>
        <path d="M11.0347 234.582C10.7686 234.981 14.6923 243.249 15.1245 243.249C15.9226 243.249 15.6898 242.419 13.7612 238.468C11.7662 234.45 11.4337 233.918 11.0347 234.582Z" fill="black"/>
        <path d="M28.1257 267.756C28.1257 268.088 28.8905 270.047 29.8215 272.139C30.7858 274.231 31.8831 276.788 32.3153 277.784C32.7143 278.78 33.2463 279.644 33.4126 279.71C34.2106 279.976 33.6786 278.216 31.484 273.401C30.2538 270.612 29.1232 268.055 29.0235 267.723C28.7575 266.992 28.1257 266.992 28.1257 267.756Z" fill="black"/>
        <path d="M34.1109 279.645C34.1109 280.375 43.122 289.407 43.8535 289.407C44.8178 289.407 44.452 288.61 43.0554 287.581C42.2907 287.017 40.0629 284.891 38.101 282.832C36.1392 280.807 34.4434 279.113 34.3104 279.113C34.2107 279.113 34.1109 279.346 34.1109 279.645Z" fill="black"/>
        </svg>
    </>
  )
}

export default Pinos 