import React from 'react'
import Menu from './Menu'
import SocialMedia from './SocialMedia'
import Button from './Button'
import About from './About'

const Popup = ({openMenu, darkMode, isAbout, setIsAbout, setOpenMenu, setActivePopup}) => {
  const classAbout = isAbout ? 'is-about' : '';
  return (
    <div className={`${openMenu ? 'translate-y-[0]': 'translate-y-[-100%]'} 
    fixed transition duration-200 
    left-0 top-0 w-full h-[100vh] z-30 
    ${darkMode ? 'bg-[rgba(44,66,82,0.9)]': 'bg-[rgba(0,128,200,0.95)]'}
   overflow-x-hidden overflow-y-auto pt-[20px] lg:pt-[40px]`}>
        <div className={`flex w-[200%]  ${classAbout}`}>
          <div className='w-1/2'>
            <div className='relative px-[20px] lg:px-[70px] py-[40px] 
            lg:py-[80px] xg:py-[90px] flex justify-center
            lg:justify-start flex-wrap max-w-[1440px] mx-auto z-10'>
                <div className='mb-[20px] lg:mb-[30px]'> <Menu darkMode={darkMode} setIsAbout={setIsAbout} /></div>
                <div className='w-full flex justify-center lg:justify-start mb-[55px]'>
                    <SocialMedia />
                </div>
                <div className='flex items-center w-full flex-wrap lg:flex-nowrap'>
                    <Button title="Becoop Wallet" classes="secondary big mb-[20px] lg:mb-0 lg:mr-[20px] lg:mr-[80px] "
                    href='https://becoop.app/' />
                    {/* <Button title="Governance App" classes="secondary big" /> */}
                </div>
            </div>  
          </div>
          
          <div className='relative  w-1/2 text-white flex justify-center
          px-[20px] lg:px-[70px] py-[40px] 
          lg:py-[80px] xg:py-[90px] flex justify-center
          lg:justify-start flex-wrap max-w-[1440px] mx-auto z-10'>
             <About setIsAbout={setIsAbout} setOpenMenu={setOpenMenu} setActivePopup={setActivePopup} />
          </div>
        </div>
        
    </div>
  )
}

export default Popup