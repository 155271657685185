import React from 'react';
import play from '../assets/svg/play-button.svg';
  

const Button = ({title="Play Video", type, classes='', href="", action}) => { 
  const icon = type == 'youtube' ? <img src={play} /> : ''; 
  return (
    <>
      {
      href === '' ? (<button className={`btn ${classes}`} onClick={action} >
                      {icon}
                      {title}
                  </button>) :
                   (<a href={href} target='_blank' className={`btn ${classes}`}>
                      {icon}
                      {title}
                  </a>)
      } 
    </>
    
  )
}

export default Button