import React from 'react';
 
import DiscordSvg from './svg/DiscordSvg';
import KeybaseSvg from './svg/KeybaseSvg';
import TwitterSvg from './svg/TwitterSvg';
import MediumSvg from './svg/MediumSvg';
import YoutubeSvg from './svg/YoutubeSvg';


const SocialMedia = () => {
  return (
    <ul className='inline-flex justify-between social-media items-center'>
        <li className='mr-6 sm:mr-9 lg:mr-14'>
            <a target='_blank' href='https://discord.com/invite/H3phGpkykH'>
                <DiscordSvg /> 
            </a>
        </li>
        <li className='mr-6 sm:mr-9 lg:mr-14'>
            <a target='_blank' href='https://keybase.io/team/tellus'>
                <KeybaseSvg />
            </a>
        </li>
        <li className='mr-6 sm:mr-9 lg:mr-14'>
            <a target='_blank' href='https://twitter.com/telluscoop'>
                <TwitterSvg />
            </a>
        </li>
        <li className='mr-6 sm:mr-9 lg:mr-14'>
            <a target='_blank' href='https://telluscoop.medium.com/'>
                <MediumSvg />
            </a>
        </li>
        <li className=''>
            <a target='_blank' href='https://www.youtube.com/@telluscoop'>
                <YoutubeSvg />
            </a>
        </li>
    </ul>
  )
}

export default SocialMedia