import React from 'react'

const Alpacas = () => {
  return (
    <>
      <svg width="146" height="107" viewBox="0 0 146 107" fill="none" 
      xmlns="http://www.w3.org/2000/svg" className='element-item' id='alpacas'>
      <path fillRule="evenodd" clipRule="evenodd" d="M45.0263 1.83147C44.3969 3.0935 44.5294 6.18214 45.3906 8.97188C46.0863 11.2967 46.6163 11.7948 48.1401 11.5291C49.7963 11.2302 50.1938 10.8317 50.1938 9.50326C50.1938 8.04197 48.3057 1.9311 47.6432 1.26688C46.815 0.403387 45.6225 0.669077 45.0263 1.83147Z" fill="#ACBFCD"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M61.589 5.88309C58.1771 9.27063 57.9121 9.90164 59.3365 11.2301C60.7277 12.5253 61.7215 12.3261 63.6759 10.3002C66.1603 7.74291 67.4191 5.81666 67.4191 4.58785C67.4191 3.29261 66.8891 2.76123 65.6634 2.76123C64.8684 2.76123 64.0734 3.35903 61.589 5.88309Z" fill="#ACBFCD"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M45.1587 13.5882C44.3637 13.9867 44.2312 14.2856 44.2312 15.4812C44.2312 16.2451 44.3968 17.7728 44.5625 18.8688C44.7281 19.9647 45.1256 23.9833 45.3906 27.8358C45.9869 36.3711 45.755 35.9725 50.8894 36.6035C52.7776 36.836 54.8314 37.2346 55.4608 37.5003C56.852 38.0648 57.9452 38.0981 58.4089 37.5335C58.8727 37.0021 59.9327 31.6883 60.7608 25.6771C61.1583 23.0202 61.589 19.9315 61.7877 18.8355C61.9534 17.7064 62.119 16.6436 62.119 16.4443C62.119 15.5144 60.2308 14.983 53.672 14.0531C45.7219 12.8907 46.3181 12.9239 45.1587 13.5882Z" fill="#CCCACF"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M2.4931 30.6588C2.12872 30.8913 1.99622 31.6219 1.99622 33.5482C2.02934 37.6 3.71874 40.0576 6.86566 40.6222C7.95881 40.8215 8.32319 40.755 9.05195 40.124L9.91321 39.3934L7.72693 36.8029C6.50128 35.408 5.11001 33.4154 4.58001 32.3858C3.58624 30.4263 3.28811 30.1607 2.4931 30.6588Z" fill="#ACBFCD"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M100.909 35.0097C98.9545 37.0023 97.2651 40.8548 98.1595 41.4194C98.292 41.4859 98.9545 41.6519 99.617 41.7848L100.843 42.0172L101.969 39.7257C103.592 36.5374 104.056 34.8104 103.526 34.1462C102.896 33.3823 102.4 33.5484 100.909 35.0097Z" fill="#ACBFCD"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M84.0812 35.5407C83.5181 35.74 83.5512 37.1349 84.1143 38.2308C84.3793 38.729 85.4725 39.991 86.5325 41.0538C88.4538 42.9136 88.52 42.9468 89.8119 42.6811C91.8988 42.2494 91.7332 41.3859 89.05 38.5962C86.3337 35.74 85.2075 35.0758 84.0812 35.5407Z" fill="#ACBFCD"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M97.3977 43.146C91.6339 43.9098 87.8907 44.7069 87.0626 45.3711C86.0688 46.135 86.7313 52.3123 88.6195 60.0505C90.4082 67.3237 90.5407 67.4566 95.3439 67.7555C97.9608 67.9215 98.8552 67.8219 100.777 67.2573C102.068 66.8588 103.327 66.2942 103.559 65.9953C103.923 65.5303 103.99 63.438 103.923 54.6038C103.857 48.659 103.725 43.6773 103.592 43.5445C103.294 43.2788 98.7227 42.9799 97.3977 43.146Z" fill="#CCCACF"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M140.56 61.2797C140.096 62.2428 138.639 64.2687 137.347 65.7632C136.055 67.2577 134.995 68.6194 134.995 68.7854C134.995 68.9847 135.326 69.1175 135.757 69.1507C136.155 69.1507 137.181 69.2504 138.01 69.3832C139.335 69.5493 139.666 69.4496 140.593 68.6858C142.183 67.3241 142.614 66.1949 142.581 63.6377C142.515 59.3866 141.885 58.6228 140.56 61.2797Z" fill="#CCCACF"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M51.2538 8.07533C50.4588 8.5735 49.8295 8.70635 49.0676 8.54029C47.4775 8.2746 45.3575 8.93883 44.0325 10.1676C42.5418 11.5625 42.31 12.8578 43.3037 14.3855C43.7012 15.0165 44.1981 15.9796 44.3969 16.511C44.96 18.1383 48.0738 19.3007 49.4651 18.404C49.7632 18.238 50.1938 18.404 50.6907 18.9022C52.2145 20.4299 54.732 20.3303 56.6533 18.6697C57.7464 17.7066 57.8127 17.6734 58.7733 18.1715C61.324 19.5 64.1065 18.3044 64.1065 15.9132C64.1065 14.4851 62.5828 12.5921 60.9265 11.9943C60.1315 11.6954 59.8002 11.3965 59.8002 10.8651C59.8002 9.93516 58.4421 8.50708 56.9846 7.87607C55.2952 7.17863 52.5789 7.27826 51.2538 8.07533Z" fill="#F9F9F9"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M27.9004 33.0168C27.2379 33.2161 25.946 34.0463 25.0847 34.8434L23.4616 36.2715L22.1366 35.6073C19.089 34.1128 14.849 34.5113 13.1264 36.4708C12.5964 37.1018 12.0664 37.8988 11.9339 38.2642C11.8014 38.7291 11.437 38.9616 10.8408 38.9616C9.64826 38.9948 7.46197 40.0244 6.56758 40.9875C6.17008 41.4192 5.44132 42.6812 4.91131 43.7772C4.01692 45.7035 3.9838 45.9692 3.9838 51.0837C3.9838 55.9325 4.05005 56.5303 4.74568 58.058C6.0707 60.9806 7.09759 62.143 9.21762 63.2058C11.6027 64.4014 11.9008 64.4014 13.8221 63.4383C15.114 62.774 15.4452 62.7408 16.2734 63.1062C17.499 63.6043 21.1759 63.6707 22.1366 63.1726C22.5341 62.9401 23.561 62.0434 24.4222 61.1135L26.0123 59.4197L26.741 60.1504C27.9004 61.2131 29.9542 61.9438 31.1136 61.7445C32.008 61.5784 32.2067 61.6781 32.7036 62.5416C33.4324 63.8368 36.1818 65.1985 38.1031 65.1985C39.66 65.1985 41.1506 64.6339 41.8794 63.7372C42.2106 63.3386 42.7738 63.3054 44.5625 63.4383C47.3451 63.6707 48.3388 63.3054 50.1276 61.4456C50.8564 60.6817 51.8502 59.785 52.3139 59.4529C53.2083 58.8219 53.9702 57.4602 54.3014 55.8661C54.4339 55.2351 54.7652 54.9362 55.4608 54.8033C56.7859 54.5377 58.3759 52.8107 58.8396 51.1501C59.2371 49.6224 59.0384 47.6629 58.2765 46.2348C57.8459 45.3381 57.8459 45.2053 58.4753 44.3418C60.2972 41.851 59.2703 37.0021 56.4546 34.8766C55.3946 34.1128 53.4402 34.0463 52.347 34.777C51.5851 35.2751 51.5189 35.2751 51.1545 34.6109C50.9558 34.2456 50.1276 33.6146 49.3326 33.2825C47.4113 32.419 45.6557 32.8507 44.2975 34.4781L43.37 35.6405L42.5419 34.777C40.72 32.8175 37.8712 32.3526 36.4468 33.7806L35.6187 34.6109L34.1943 33.8139C32.538 32.8839 29.7223 32.5186 27.9004 33.0168Z" fill="#F9F9F9"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M94.1182 38.6295C93.6213 38.7955 92.8926 39.1941 92.5282 39.5262C92.1638 39.8583 91.5013 40.0575 91.0707 40.0243C89.9113 39.8583 87.5594 41.0871 86.6319 42.3491C86.1681 42.9801 85.8037 44.0429 85.7043 44.9396C85.5056 47.0651 86.5656 48.3604 88.5863 48.46C89.3482 48.4932 90.2094 48.7257 90.5407 49.0246C91.5676 49.9213 92.5282 50.2534 94.052 50.2534C95.377 50.2534 95.7414 50.0873 96.8345 48.9582C97.7952 47.9618 98.1927 47.7625 98.7227 47.9618C99.8489 48.3936 101.704 48.2939 102.499 47.7293C103.128 47.2644 103.228 46.9655 103.095 46.102C102.963 45.2717 103.062 44.84 103.559 44.4082C105.282 42.8473 103.426 40.29 100.578 40.29C99.4183 40.29 98.822 40.124 98.3914 39.6258C98.0602 39.2605 97.2652 38.8287 96.6026 38.6295C95.2445 38.2641 95.4101 38.2641 94.1182 38.6295Z" fill="#F9F9F9"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M98.8883 62.2431C98.4246 62.4423 97.7289 62.8741 97.3314 63.2394C96.7683 63.804 96.5033 63.8704 95.9733 63.5715C93.9195 62.4756 90.3088 64.3354 89.315 66.9591C88.5863 68.8853 89.0832 71.3762 90.3751 72.4389C90.7726 72.8042 90.7726 73.0367 90.2094 74.1659C89.5801 75.3947 89.5801 75.5608 90.0769 77.2878C90.6069 79.0812 91.8326 80.5093 93.2239 80.9742C93.6214 81.1071 93.9195 81.6052 94.052 82.5351C94.2176 83.4983 94.6483 84.2621 95.5095 85.1256C96.172 85.823 97.0002 86.919 97.2983 87.5832C98.3252 89.6756 101.141 90.8712 103.46 90.1737C104.42 89.8748 104.752 89.8748 105.182 90.2734C106.805 91.7346 110.449 92.2328 112.172 91.2033C112.669 90.9044 113.43 90.0077 113.828 89.2438C114.524 87.9486 114.656 87.8821 115.782 88.015C116.61 88.1478 117.174 88.015 117.737 87.55C118.465 86.9522 118.598 86.9522 120.122 87.4172C121.016 87.6829 121.745 88.1146 121.745 88.3139C121.745 89.0113 123.699 90.4062 124.991 90.6387C125.786 90.8047 126.681 90.7051 127.575 90.373C128.801 89.9412 129.033 89.9412 130.059 90.5058C131.649 91.3361 133.968 91.3029 135.36 90.3398C138.308 88.3471 139.699 84.1625 139.765 76.9889C139.798 72.6382 139.732 72.2397 138.97 70.7119C137.877 68.5532 136.088 67.1251 134.432 67.0587C133.438 67.0255 133.041 66.8262 132.511 66.0292C132.146 65.4978 131.053 64.6343 130.059 64.1361C127.774 62.9405 123.997 62.8077 121.911 63.8704L120.619 64.5347L119.492 63.4719C118.465 62.4423 118.2 62.3759 115.849 62.2763C113.795 62.1767 113.033 62.3095 112.006 62.8409C110.847 63.4387 110.681 63.4719 109.952 62.9737C108.892 62.2763 106.11 62.542 104.884 63.4719C104.023 64.1029 103.957 64.1029 103.625 63.5715C103.195 62.8741 101.207 61.8778 100.313 61.911C99.9815 61.911 99.3521 62.077 98.8883 62.2431Z" fill="#F9F9F9"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M25.2172 32.1865C22.1697 32.585 16.6377 33.847 14.7496 34.5777C13.9214 34.9098 13.7558 34.9762 13.9545 37.8988C14.2527 41.7181 15.3458 44.1093 17.3996 46.3677C19.3871 48.5264 21.5403 49.7552 24.4222 50.2866C32.7698 51.8143 40.7531 43.8436 39.7924 34.9098L39.5606 32.7843L37.0099 32.2197C34.0286 31.5555 29.921 31.5222 25.2172 32.1865Z" fill="#FF5F8A"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M35.6849 35.5402C35.0886 40.4554 33.2005 43.7434 30.1861 45.1382C28.066 46.1346 26.2773 46.1346 24.1903 45.1382C21.3084 43.8098 18.8571 40.3558 17.9628 36.4037C17.3665 33.8464 16.5384 33.5475 16.8365 35.9719C17.2671 39.7248 20.3809 44.3744 23.4947 46.0017C25.5485 47.0645 28.1654 47.1641 30.2523 46.2674C32.4386 45.3043 34.6911 42.8799 35.6186 40.4222C36.3805 38.3299 37.043 34.1785 36.778 33.2486C36.3805 32.0198 36.0493 32.7172 35.6849 35.5402Z" fill="#78CCFB"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M110.151 61.8105L108.66 62.0429L108.561 64.3677C108.263 70.8107 112.138 76.357 117.737 77.5194C122.407 78.4825 126.449 76.5562 128.834 72.1724C129.529 70.8771 131.517 64.5338 131.252 64.3345C130.821 64.0024 128.768 63.1389 126.879 62.5411C124.892 61.9101 123.633 61.8105 118.101 61.7108C114.557 61.6444 110.979 61.7108 110.151 61.8105Z" fill="#78CCFB"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M110.482 64.3349C110.482 68.3535 113.066 72.4384 116.379 73.7337C120.917 75.4939 126.151 73.2687 128.403 68.6192C129.298 66.7593 129.695 63.7039 128.999 63.9364C128.734 64.036 128.436 64.7334 128.304 65.4973C127.708 69.0509 125.091 72.1063 121.811 73.0363C116.577 74.564 111.476 70.2465 111.476 64.3349C111.476 62.9732 111.344 62.5415 110.979 62.5415C110.615 62.5415 110.482 62.9732 110.482 64.3349Z" fill="#FF5F8A"/>
      <path d="M44.7944 1.66523C44.3969 2.03056 44.5294 2.42909 45.0594 2.42909C45.8213 2.42909 46.7488 4.2225 47.1794 6.41444C47.5438 8.24106 48.0075 9.07134 48.4382 8.63959C48.5044 8.53996 48.3388 7.31114 48.0407 5.94948C47.3781 2.92726 45.6887 0.735321 44.7944 1.66523Z" fill="black"/>
      <path d="M64.6697 4.45463C63.1791 5.41776 59.8003 9.13742 59.8003 9.76843C59.8003 10.4991 60.7278 9.90128 61.9535 8.37356C63.7422 6.1152 65.9285 4.45463 66.7566 4.6539C67.2535 4.78675 67.4192 4.6539 67.4192 4.12252C67.4192 3.12619 66.4916 3.22582 64.6697 4.45463Z" fill="black"/>
      <path d="M59.469 11.6949C59.469 11.861 59.999 12.1931 60.6284 12.3923C63.4109 13.3222 64.3716 16.5437 62.2184 17.8058C61.1915 18.4036 61.1584 18.4036 59.8334 17.7393C59.1046 17.3408 58.409 16.8758 58.3096 16.6766C58.144 16.4441 57.7133 16.7762 57.1171 17.4737C56.5871 18.1379 55.6264 18.8353 54.9639 19.0346C53.5064 19.5327 51.6182 19.2006 51.0882 18.3704C50.8895 18.005 50.5251 17.7061 50.2932 17.7061C50.0613 17.7061 49.8626 17.5401 49.8626 17.374C49.8626 17.1747 49.7301 17.0419 49.5313 17.0419C49.3657 17.0419 49.2001 17.2744 49.2001 17.5401C49.2001 18.2043 47.5107 18.2043 46.2519 17.5401C45.7219 17.2744 45.1256 16.6102 44.8938 16.0456C44.6619 15.5142 44.3638 15.0492 44.1981 15.0492C43.0719 15.0492 44.3306 17.5733 45.9207 18.4368C46.55 18.8021 47.6101 19.0346 48.3719 19.0014C49.1338 18.9682 50.3264 19.2338 51.1876 19.6324C52.0158 20.0309 52.9433 20.363 53.3076 20.363C54.5002 20.363 56.5208 19.5992 57.3821 18.7689C58.2102 18.005 58.3096 17.9718 59.1709 18.5032C60.5621 19.3335 62.2184 19.1674 63.4441 18.1379C64.4378 17.3076 64.5041 17.1415 64.3384 15.5806C64.2059 14.1857 64.0072 13.754 62.8478 12.658C61.8872 11.7613 61.1915 11.396 60.4628 11.396C59.9328 11.396 59.469 11.5288 59.469 11.6949Z" fill="black"/>
      <path d="M48.6701 20.7615C48.6701 21.8574 49.7301 21.7246 49.962 20.5622C50.0613 20.0972 49.8295 19.7651 49.3988 19.7651C48.9682 19.7983 48.6701 20.1969 48.6701 20.7615Z" fill="black"/>
      <path d="M57.4815 20.5631C57.1502 21.1277 57.5808 21.958 58.2433 21.958C58.4752 21.958 58.674 21.5262 58.674 21.0281C58.674 20.065 57.9783 19.7661 57.4815 20.5631Z" fill="black"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M52.3138 23.3193C51.7838 22.7547 51.5188 22.1901 51.6182 21.8912C51.7838 21.4927 52.2807 21.3599 53.5064 21.3599C55.527 21.3599 55.9245 21.9577 54.8645 23.3525C53.9039 24.681 53.9701 24.8803 55.4276 25.1459C57.1833 25.4116 60.264 26.9726 62.7484 28.8324C64.7359 30.2937 65.034 30.4265 67.0547 30.493C69.6385 30.5926 71.8248 31.3897 71.6591 32.1867C71.4935 33.0834 69.4066 34.0466 67.9822 33.8805C67.0216 33.7477 66.2265 33.2163 64.3053 31.4229C62.9471 30.194 61.1915 28.7992 60.3965 28.3674C59.0714 27.6368 59.1377 27.7364 61.3902 29.7955C63.5103 31.7882 64.3053 32.9838 63.5103 32.9838C63.3778 32.9838 62.5827 32.2864 61.7877 31.4561C60.0983 29.7291 56.9845 27.4375 54.8976 26.3748C53.5726 25.6773 53.407 25.6773 52.4463 26.1755C51.2538 26.7401 50.8894 26.574 51.4195 25.7105C51.7507 25.1792 51.6845 25.0463 51.0882 24.8803C50.6907 24.7806 49.5644 24.681 48.6369 24.681C47.3119 24.681 46.8813 24.5481 46.8813 24.1828C46.8813 23.5186 49.4982 23.5186 51.6182 24.1496C52.4795 24.4153 53.1751 24.5149 53.1751 24.4153C53.1751 24.3157 52.7776 23.8175 52.3138 23.3193ZM69.8704 32.5517C70.4667 32.12 70.4667 32.0868 69.9366 31.8875C69.6054 31.7546 68.5122 31.5554 67.4854 31.4557L65.5972 31.2897L66.5247 32.12C67.5516 33.0831 68.9429 33.2492 69.8704 32.5517Z" fill="black"/>
      <path d="M2.39376 30.5923C1.56563 31.489 1.63188 35.3747 2.52627 37.3009C3.25503 38.9615 4.71255 40.622 5.37506 40.622C6.00445 40.622 5.90507 40.1571 5.14318 39.4597C3.95066 38.3637 3.15565 36.7363 2.79127 34.6772C2.49314 33.0167 2.52627 32.5185 2.9569 31.6218C3.78504 29.928 3.48691 29.3634 2.39376 30.5923Z" fill="black"/>
      <path d="M39.1962 32.8179C39.0968 32.9175 39.0968 33.9471 39.163 35.0763C39.6268 40.8218 36.4468 46.5009 31.3786 48.9918C29.4241 49.9549 28.861 50.0878 26.3435 50.0878C23.9253 50.0878 23.2297 49.9549 21.474 49.1246C18.9896 47.9954 16.3727 45.3718 15.2464 42.8477C14.352 40.9215 13.7558 37.4011 13.9877 35.7073C14.1202 34.9102 14.0539 34.6445 13.6564 34.6445C12.5301 34.6445 12.762 39.1613 14.0539 42.582C16.9358 50.2206 25.9128 53.4753 32.8692 49.3903C37.5068 46.667 40.6537 40.5893 40.1899 35.1427C40.0574 33.4157 39.6268 32.4194 39.1962 32.8179Z" fill="black"/>
      <path d="M102.135 34.5444C101.207 35.574 99.7496 38.0648 99.3852 39.194C99.2527 39.7254 99.3189 39.9578 99.617 39.9578C99.8821 39.9578 100.511 39.0611 101.075 37.932C101.605 36.8028 102.433 35.5408 102.963 35.109C103.493 34.6773 103.791 34.1791 103.691 33.9798C103.36 33.4484 103.062 33.5813 102.135 34.5444Z" fill="black"/>
      <path d="M83.6506 36.4374C83.6506 36.703 84.015 37.0019 84.4456 37.1016C84.9093 37.2012 85.9031 38.0647 86.665 38.9614C87.5925 40.0574 88.1888 40.5223 88.3875 40.3231C88.6194 40.0906 88.2881 39.4596 87.4931 38.4632C85.8369 36.4706 83.6506 35.3082 83.6506 36.4374Z" fill="black"/>
      <path d="M58.1439 37.4336C58.1439 37.5 58.3758 38.1975 58.6408 38.9613C59.3365 40.954 59.2702 42.5814 58.3427 44.408L57.5477 45.9689L58.2433 47.2641C58.9058 48.4265 58.939 48.659 58.5414 50.1535C58.0446 51.9469 56.6202 53.6407 55.0301 54.2717C53.8708 54.7699 53.5064 55.1352 53.5064 55.9987C53.5064 57.3604 52.4132 58.9545 51.0551 59.5855C49.3326 60.3826 45.6225 60.4158 44.1981 59.6851C42.84 58.9877 42.3762 59.2534 42.1112 60.8143C41.9787 61.6778 41.5481 62.5413 40.8856 63.1723C39.9912 64.069 39.6599 64.2019 38.0699 64.2019C35.8836 64.2019 34.6248 63.6041 33.4323 62.0431C32.2729 60.4822 31.5773 61.0136 32.6042 62.6742C33.3329 63.8366 35.5855 65.1982 36.8111 65.1982C37.4405 65.1982 37.5068 65.5635 37.9043 70.2463C38.2686 74.9291 38.5668 76.2908 39.1299 75.7262C39.2624 75.6265 39.1962 73.7335 38.9643 71.5416C38.4011 65.3311 38.4011 65.2978 39.7262 65.0654C41.0512 64.7997 42.3431 63.4712 42.9062 61.711C43.2043 60.7479 43.4362 60.5486 43.8668 60.7479C44.9931 61.3125 49.1669 61.2793 50.8563 60.7147C52.7445 60.0837 53.7051 59.0209 54.2683 56.995C54.5664 55.7662 54.8645 55.4673 56.0902 54.8363C58.8727 53.5079 60.4627 49.6221 59.2702 47.0981C58.7402 45.9357 58.7402 45.8029 59.2371 44.9394C60.1315 43.4449 60.264 41.2861 59.6677 39.1938C59.3365 37.9982 58.939 37.3008 58.6408 37.3008C58.3758 37.3008 58.1439 37.3672 58.1439 37.4336Z" fill="black"/>
      <path d="M8.29012 42.3157C7.16385 43.7106 6.23634 45.504 5.97133 47.0317C5.77258 48.028 5.83883 48.2605 6.26946 48.2605C6.56759 48.2605 6.79947 47.9948 6.79947 47.5963C6.79947 46.4671 7.72698 44.6073 8.95263 43.2124C10.0126 42.05 10.2114 41.2861 9.44951 41.2861C9.31701 41.2861 8.787 41.7511 8.29012 42.3157Z" fill="black"/>
      <path d="M103.46 42.6819C103.393 43.5454 103.128 43.8775 102.3 44.2761C101.207 44.7742 101.008 45.4717 102.002 45.4052C102.731 45.3388 102.698 46.3684 101.936 47.0326C101.075 47.8297 98.8552 47.7632 97.7952 46.933C96.7352 46.1027 96.4371 46.1027 96.6689 46.933C96.7683 47.3647 96.5696 47.9625 96.0727 48.5271C95.4102 49.291 95.0458 49.4238 93.4889 49.4238C91.932 49.4238 91.4682 49.2577 90.2757 48.2946C89.3151 47.5308 88.8182 47.2983 88.6526 47.564C88.2882 48.1618 86.7313 47.4643 86.4663 46.5676C86.3338 46.1359 86.2013 45.372 86.1681 44.8074C86.1019 43.2133 85.3069 43.3129 85.3069 44.9403C85.3069 47.0326 86.0688 48.1618 87.725 48.6267C88.4869 48.8592 89.8451 49.3574 90.7395 49.7891C91.667 50.2209 92.8264 50.5862 93.3233 50.5862C94.5489 50.5862 96.2383 49.8555 97.0002 48.992C97.5302 48.4275 97.8946 48.361 99.5509 48.4939C101.406 48.6599 101.572 48.6267 102.499 47.6636C103.128 47.0658 103.526 46.3019 103.526 45.7706C103.526 45.3056 103.758 44.5417 104.056 44.11C104.387 43.5786 104.52 42.9808 104.387 42.4494C104.089 41.287 103.592 41.4199 103.46 42.6819Z" fill="black"/>
      <ellipse cx="100.048" cy="50.4196" rx="0.761887" ry="0.763858" fill="black"/>
      <path d="M5.37501 50.4523C5.2425 50.5851 5.14313 51.6479 5.14313 52.8103C5.14313 54.3712 5.2425 54.9358 5.57376 54.8362C5.80564 54.7365 6.00439 53.9395 6.07064 52.7439C6.17002 50.9172 5.87189 49.9541 5.37501 50.4523Z" fill="black"/>
      <path d="M90.0439 50.6845C89.4476 51.2491 89.6464 51.8469 90.4414 51.8469C90.8389 51.8469 91.2033 51.4816 91.2033 51.083C91.2033 50.286 90.607 50.0867 90.0439 50.6845Z" fill="black"/>
      <path d="M94.3501 51.2499C93.3563 51.6484 93.3895 52.3458 94.4163 52.9104C94.8801 53.1429 95.2445 53.5746 95.2445 53.8403C95.2445 54.2389 95.1451 54.2389 94.7476 53.9068C94.1513 53.4086 93.7538 53.6743 94.052 54.4049C94.3832 55.3016 95.8076 55.6337 96.9339 55.0692C98.1264 54.4381 98.2258 53.7407 97.0664 54.0396C96.0395 54.3053 95.9732 53.7407 96.9339 52.6779C97.5301 52.0801 97.5964 51.8477 97.232 51.4159C96.7683 50.8513 95.4764 50.7849 94.3501 51.2499Z" fill="black"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M90.7063 54.2394C88.8513 55.0697 86.4331 56.6971 84.9094 58.1583L83.6506 59.3539L81.5968 58.6233C78.5162 57.5938 76.4292 57.3613 76.1642 58.0255C75.6674 59.2875 77.7874 61.0477 80.3381 61.4795L81.63 61.7119L80.4706 63.5386C78.7812 66.1955 79.0462 66.7601 81.4974 65.7305C82.8556 65.1327 83.7831 63.7046 84.3462 61.413C84.81 59.3872 85.4394 58.6233 88.3213 56.6971C91.6007 54.5383 91.932 54.5715 89.9445 56.8299C86.8307 60.3835 84.81 63.8043 85.4725 64.4685C85.7375 64.7342 86.0356 64.3024 87.5925 61.5459C88.2219 60.4167 89.6132 58.5901 90.6732 57.4609C92.6939 55.269 92.8264 55.0697 92.197 54.804C91.932 54.7044 91.9982 54.6047 92.3626 54.6047C92.992 54.5715 93.1576 53.5752 92.5282 53.5752C92.2964 53.5752 91.4682 53.8741 90.7063 54.2394ZM80.5368 59.2542C82.7893 59.9516 82.9881 60.5494 80.9011 60.5494C79.8743 60.5494 77.3567 59.3871 77.3567 58.8889C77.3567 58.3907 78.0192 58.4903 80.5368 59.2542ZM81.0005 64.7337C81.0005 65.1655 82.3918 63.7374 82.8887 62.7743C83.1537 62.2429 83.3193 61.7447 83.2199 61.6783C83.0874 61.5122 81.0005 64.3684 81.0005 64.7337Z" fill="black"/>
      <path d="M98.557 54.2384C98.557 54.6369 98.557 54.6369 101.24 55.3675C102.234 55.6664 103.625 56.2642 104.321 56.7292C105.712 57.6923 106.176 57.7587 106.176 57.0613C106.176 56.4303 103.824 55.1018 101.571 54.4376C99.4846 53.8066 98.557 53.7734 98.557 54.2384Z" fill="black"/>
      <path d="M16.8365 60.3498C16.0746 61.3129 14.9152 62.3757 14.2858 62.6746C11.9008 63.8038 9.11823 63.0067 7.16383 60.6487C5.93818 59.1542 5.40817 59.4531 6.30256 61.1469C6.79945 62.0436 7.49508 62.6414 8.98573 63.372L11.0064 64.3684L11.2051 66.5271C11.5695 70.4128 12.5633 76.8226 12.8283 76.8226C13.4245 76.8226 13.4245 75.8927 12.9608 72.5715C12.6627 70.6121 12.2983 67.922 12.1658 66.5935L11.9008 64.2023H12.762C13.8883 64.2023 16.439 62.575 17.5321 61.1469L18.3934 59.9845L21.9378 60.1506C24.7535 60.2502 25.7804 60.1838 26.8073 59.752C27.7348 59.3535 27.9998 59.0878 27.7679 58.8553C27.5029 58.5896 27.2048 58.6228 26.6747 58.8885C25.9129 59.2871 19.6521 59.2206 18.7246 58.7889C18.3602 58.6228 17.8302 59.0878 16.8365 60.3498Z" fill="black"/>
      <path d="M141.455 59.7848C141.852 60.6815 142.051 64.0358 141.819 65.3311C141.686 66.0285 140.991 67.1577 140.13 68.1208C138.539 69.9142 138.374 70.1799 139.103 70.1799C139.865 70.1799 142.216 67.357 142.581 65.9953C143.31 63.3716 142.78 59.2202 141.72 59.2202C141.355 59.2202 141.289 59.3863 141.455 59.7848Z" fill="black"/>
      <path d="M108.164 64.6998C108.164 67.2571 108.959 70.3125 110.052 72.1391C111.079 73.7997 113.629 76.2241 115.285 77.0876C116.71 77.8515 117.339 77.9843 119.923 77.9843C122.54 77.9843 123.103 77.8515 124.66 77.0544C126.946 75.8588 128.801 73.8993 130.026 71.3753C131.418 68.5191 132.146 64.5338 131.252 64.5338C130.987 64.5338 130.689 65.3973 130.424 66.8586C129.066 74.8293 122.242 79.2131 115.948 76.2241C111.741 74.2315 109.157 69.7147 109.157 64.4673C109.157 62.6739 109.058 62.209 108.66 62.209C108.263 62.209 108.164 62.7072 108.164 64.6998Z" fill="black"/>
      <path d="M17.9296 69.7487C18.0621 73.3688 18.1946 76.391 18.2609 76.4574C18.294 76.4906 18.4928 76.4574 18.6915 76.3246C19.3872 75.8928 18.8572 63.5382 18.1284 63.2726C17.7971 63.1729 17.7309 64.4017 17.9296 69.7487Z" fill="black"/>
      <path d="M44.3969 64.6008C44.0656 66.5602 43.2375 73.9663 43.2375 74.9295C43.2375 75.5273 43.37 75.8262 43.6681 75.7597C44.0656 75.5937 45.6225 64.3351 45.3244 63.7705C45.0263 63.2059 44.5956 63.6044 44.3969 64.6008Z" fill="black"/>
      <path d="M89.6132 65.8626C88.3544 67.1246 88.2882 70.9771 89.5469 72.7373C90.0107 73.4015 90.1432 73.8665 89.9113 74.1986C89.7457 74.4311 89.6132 75.4274 89.6132 76.3573C89.6132 78.3832 90.5076 79.9109 92.3626 81.1065C93.1576 81.6379 93.5882 82.1693 93.5882 82.6343C93.5882 83.9295 95.377 85.9554 97.232 86.7524C98.9877 87.5163 101.538 87.6159 102.963 86.9849C103.393 86.7857 103.526 86.8521 103.526 87.317C103.526 88.878 105.944 91.6677 107.799 92.2655L108.594 92.498L108.395 98.0443C108.263 101.066 108.263 103.889 108.362 104.321C108.859 106.115 109.157 104.487 109.323 98.941C109.422 95.6863 109.588 92.8633 109.687 92.7305C109.787 92.5644 110.184 92.4316 110.582 92.4316C111.575 92.4316 112.669 91.6345 113.828 90.0071C114.755 88.7451 115.12 87.1178 114.49 87.1178C114.325 87.1178 114.027 87.5827 113.762 88.1805C113.53 88.7451 112.834 89.7082 112.238 90.3393C111.277 91.2692 110.913 91.4352 109.488 91.4352C108.13 91.4352 107.667 91.2692 106.673 90.4057C105.215 89.1104 104.52 87.8152 104.52 86.4535C104.52 85.3244 103.824 85.1251 102.731 85.9554C102.366 86.2543 101.373 86.4535 100.412 86.4535C97.3314 86.4535 94.582 84.3945 94.582 82.0697C94.582 81.339 94.317 81.0069 93.257 80.4755C92.4951 80.077 91.6007 79.3131 91.2694 78.7153C90.5407 77.5197 90.4082 75.2946 91.0044 73.9993C91.3357 73.2687 91.2694 73.0694 90.6401 72.372C90.2094 71.9402 89.7788 71.0435 89.6132 70.3793C89.2488 68.7852 89.845 66.4936 90.6732 66.2611C91.4019 66.0951 91.4351 65.6633 90.7726 65.3976C90.4744 65.298 89.9776 65.4973 89.6132 65.8626Z" fill="black"/>
      <path d="M51.5188 72.5047C49.6969 76.158 49.6969 76.158 50.2932 76.158C50.6907 76.158 53.8708 70.346 53.8376 69.5822C53.8045 68.4862 53.0426 69.4825 51.5188 72.5047Z" fill="black"/>
      <path d="M57.3821 70.5457C55.8583 73.0033 54.1689 75.9259 54.1689 76.1584C54.1689 76.3245 54.3014 76.5237 54.467 76.6234C54.9308 76.9223 58.5746 70.4793 58.1771 70.0807C57.9783 69.8815 57.6802 70.0475 57.3821 70.5457Z" fill="black"/>
      <path d="M134.001 70.1132C134.001 70.2793 134.366 70.5782 134.763 70.7443C135.194 70.9435 135.823 71.641 136.121 72.3052C136.718 73.5672 137.314 73.8661 137.314 72.903C137.314 72.2388 136.486 70.9103 135.724 70.2461C135.128 69.7811 134.001 69.7147 134.001 70.1132Z" fill="black"/>
      <path d="M4.71254 70.3793C4.61316 70.479 5.01067 71.5085 5.64005 72.6045C6.23631 73.7337 6.96507 75.4607 7.29633 76.3906C7.82634 78.0511 8.09134 78.4165 8.5551 77.9515C8.85323 77.6526 7.19695 73.2355 6.17006 71.6082C5.3088 70.1801 5.11005 70.014 4.71254 70.3793Z" fill="black"/>
      <path d="M0.903036 71.2093C1.13491 72.2056 5.2756 78.1504 5.70623 78.1504C6.40187 78.1504 6.23624 77.7851 3.91746 74.3311C1.86368 71.3753 0.571781 70.0137 0.903036 71.2093Z" fill="black"/>
      <path d="M137.148 76.3575C137.281 77.0881 137.513 78.6823 137.612 79.9775C137.711 81.2728 137.943 82.2691 138.142 82.2359C139.036 82.0034 138.308 75.5604 137.347 75.2283C136.983 75.0955 136.95 75.328 137.148 76.3575Z" fill="black"/>
      <path d="M137.778 86.1547C136.519 88.4463 133.769 90.439 131.881 90.439C130.093 90.439 128.271 89.1769 127.343 87.3503C126.946 86.5865 126.581 85.9222 126.548 85.8558C126.515 85.8226 125.554 86.0219 124.461 86.3208C122.474 86.8854 120.552 86.7525 118.697 85.9554C117.737 85.5569 117.969 86.6197 118.929 87.0514C119.89 87.4832 124.196 87.5496 125.19 87.151C125.72 86.9518 126.051 87.1843 126.846 88.4131C127.973 90.0736 130.026 91.4353 131.451 91.4353C131.948 91.4353 132.345 91.4685 132.345 91.5017C132.345 91.5681 132.113 93.3283 131.815 95.4206C130.854 102.495 130.788 103.225 131.086 103.557C131.55 104.056 132.014 103.092 132.014 101.565C132.014 100.834 132.146 99.7049 132.312 99.0739C132.478 98.4429 132.776 96.417 133.008 94.5904C133.372 91.4685 133.438 91.2028 134.266 90.8375C135.823 90.1401 137.645 88.4131 138.639 86.6529C139.765 84.7266 139.831 84.4609 139.169 84.4609C138.904 84.4609 138.275 85.2248 137.778 86.1547Z" fill="black"/>
      <path d="M125.256 90.6052C125.058 91.1366 125.985 103.757 126.25 104.023C126.316 104.122 126.548 104.023 126.747 103.823C126.979 103.591 126.913 101.532 126.548 97.0482C126.051 90.9373 125.72 89.3764 125.256 90.6052Z" fill="black"/>
      <path d="M101.671 93.993C101.87 96.8491 102.466 101.565 103.029 104.953C103.162 105.65 103.427 106.049 103.725 106.049C104.122 106.049 104.188 105.816 104.056 105.119C103.559 102.694 102.83 96.9156 102.632 93.993C102.466 91.4357 102.333 90.7715 101.936 90.7715C101.505 90.7715 101.472 91.2697 101.671 93.993Z" fill="black"/>
      <path d="M91.667 96.6822C91.7332 96.815 92.6607 98.7745 93.7208 101C95.1783 103.989 95.8408 105.051 96.3045 105.051C96.6358 105.051 96.9008 104.819 96.9008 104.553C96.9008 104.288 96.7683 104.055 96.5695 104.055C96.4039 104.055 95.4433 102.328 94.4495 100.236C93.2239 97.6453 92.462 96.4165 92.0976 96.4165C91.7995 96.4165 91.6007 96.5493 91.667 96.6822Z" fill="black"/>
      <path d="M140.626 96.8482C140.626 97.0807 139.732 98.8077 138.639 100.668C137.546 102.494 136.651 104.188 136.651 104.387C136.651 105.682 138.606 103.059 140.626 99.0734C141.753 96.8482 141.819 96.4165 141.123 96.4165C140.858 96.4165 140.626 96.6158 140.626 96.8482Z" fill="black"/>
      <path d="M142.283 101.565C140.991 103.325 139.798 104.653 139.633 104.554C139.434 104.454 139.301 104.653 139.301 105.019C139.301 105.982 140.03 105.882 141.024 104.786C141.951 103.757 145.595 98.8414 145.595 98.5757C145.595 98.4761 145.363 98.4097 145.098 98.4097C144.867 98.4097 143.575 99.8378 142.283 101.565Z" fill="black"/>
    </svg>
    </>
  )
}

export default Alpacas


