import React from 'react'

const DiscordSvg = () => {
  return (
    <svg width="48" height="37" viewBox="0 0 48 37" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M40.6141 3.04019C37.574 1.62301 34.2825 0.594407 30.8538 0.000105142C30.8237 -0.000856263 30.7938 0.00478522 30.7662 0.016633C30.7385 0.0284809 30.7138 0.0462473 30.6938 0.0686789C30.2824 0.822985 29.8023 1.80587 29.4823 2.56017C25.8457 2.01159 22.1473 2.01159 18.5106 2.56017C18.1906 1.78301 17.7106 0.822985 17.2763 0.0686789C17.2534 0.0229634 17.1848 0.000105142 17.1163 0.000105142C13.6876 0.594407 10.4189 1.62301 7.35601 3.04019C7.33315 3.04019 7.31029 3.06305 7.28743 3.0859C1.07012 12.389 -0.644214 21.4407 0.201523 30.4009C0.201523 30.4467 0.224381 30.4924 0.270097 30.5152C4.3845 33.5325 8.33889 35.3611 12.2476 36.5725C12.3161 36.5954 12.3847 36.5725 12.4076 36.5268C13.3219 35.2696 14.1448 33.9439 14.8534 32.5496C14.8991 32.4581 14.8534 32.3667 14.7619 32.3438C13.459 31.841 12.2247 31.2467 11.0132 30.5609C10.9218 30.5152 10.9218 30.3781 10.9904 30.3095C11.2418 30.1266 11.4933 29.9209 11.7447 29.7381C11.7904 29.6923 11.859 29.6923 11.9047 29.7152C19.7678 33.3039 28.248 33.3039 36.0196 29.7152C36.0654 29.6923 36.1339 29.6923 36.1797 29.7381C36.4311 29.9438 36.6825 30.1266 36.934 30.3324C37.0254 30.4009 37.0254 30.5381 36.9111 30.5838C35.7225 31.2924 34.4653 31.8638 33.1624 32.3667C33.071 32.3896 33.0481 32.5039 33.071 32.5724C33.8024 33.9667 34.6253 35.2925 35.5168 36.5497C35.5854 36.5725 35.6539 36.5954 35.7225 36.5725C39.654 35.3611 43.6084 33.5325 47.7228 30.5152C47.7685 30.4924 47.7914 30.4467 47.7914 30.4009C48.7971 20.0464 46.1228 11.0633 40.7055 3.0859C40.6826 3.06305 40.6598 3.04019 40.6141 3.04019ZM16.042 24.9379C13.6876 24.9379 11.7218 22.7664 11.7218 20.0921C11.7218 17.4177 13.6419 15.2462 16.042 15.2462C18.4649 15.2462 20.3849 17.4406 20.3621 20.0921C20.3621 22.7664 18.442 24.9379 16.042 24.9379ZM31.9738 24.9379C29.6195 24.9379 27.6537 22.7664 27.6537 20.0921C27.6537 17.4177 29.5738 15.2462 31.9738 15.2462C34.3967 15.2462 36.3168 17.4406 36.2939 20.0921C36.2939 22.7664 34.3967 24.9379 31.9738 24.9379Z" fill="white"/>
  </svg> 

  )
}

export default DiscordSvg