import React from 'react'

const Cascade = () => {
  return (
    <>
        <svg width="110" height="92" viewBox="0 0 110 92" fill="none" 
          xmlns="http://www.w3.org/2000/svg" className='element-item' id='cascade'>
        <path fillRule="evenodd" clipRule="evenodd" d="M72.941 6.37473C72.537 7.58416 68.9349 10.5069 66.2081 11.8508C64.2892 12.825 63.0099 13.161 60.9901 13.2618C58.3642 13.4297 58.2969 13.4297 56.9166 11.9515L55.5027 10.4733L50.4867 10.3054C44.1241 10.1038 44.528 9.80144 42.7101 15.9494C42.3062 17.1924 42.2725 17.226 40.6229 16.9572C39.007 16.7221 38.805 16.7892 37.5931 18.0659C36.3812 19.3425 34.5633 23.6763 33.0484 28.8835C32.5434 30.6641 32.4761 30.7313 30.7592 30.9664C25.8778 31.6719 24.0262 34.4939 19.7172 47.9992C18.7746 50.9892 17.6973 54.3823 17.3606 55.5581C17.0577 56.7339 16.5863 58.0442 16.3844 58.4809C15.1724 61.1013 15.2734 63.3522 16.6537 63.8561C18.4042 64.5616 27.1907 65.9054 35.4049 66.7789C47.7598 68.0219 70.7528 68.5259 84.791 67.8204C88.1238 67.6524 92.4329 67.518 94.3854 67.518C98.4925 67.518 98.3579 67.686 97.4153 63.5202C97.1123 62.2436 96.742 60.1607 96.6073 58.884C96.2706 55.8605 95.0251 49.9813 94.5201 49.1078C94.3181 48.7047 93.6111 47.8984 92.9715 47.2937C92.1299 46.4874 91.7596 45.7819 91.7596 44.9756C91.7596 43.9006 91.2209 40.0035 89.2684 27.5397C88.6624 23.4747 87.9891 22.2653 86.4742 22.2653C85.4643 22.2653 85.5653 22.8028 84.5217 13.161C84.2187 9.96942 83.7474 7.14742 83.5117 6.87866C83.0741 6.34114 81.3235 6.10597 76.5095 5.8708C73.547 5.70283 73.143 5.73642 72.941 6.37473Z" fill="#FEBB64"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M65.1981 0.898813C60.586 2.10824 57.5225 5.46776 53.1125 14.3369C51.3282 17.9652 49.847 21.1903 49.847 21.4591C49.847 21.6942 49.6113 22.4669 49.3083 23.1388C48.2984 25.3897 46.3459 31.6048 44.9319 36.9801C44.427 38.9958 43.821 41.2803 43.619 42.0193C43.417 42.7584 43.114 44.1358 42.9121 45.1101C42.5754 46.7227 41.7338 50.2166 40.9258 53.2401C40.7239 53.9792 40.2526 55.7262 39.9159 57.0364C39.2426 59.4889 39.2089 59.5224 38.098 59.2873C36.5158 58.9849 34.2266 59.6232 32.779 60.8327C31.6007 61.8405 31.5671 61.8405 30.2205 61.1686C25.2381 58.817 18.9091 61.0678 17.1922 65.7712C16.7546 66.8798 16.5189 67.0142 12.9168 67.8205C8.3721 68.8283 3.99569 70.7433 2.6491 72.2886C-0.683702 76.2529 2.41345 80.0155 12.3445 84.2149C16.5863 85.9955 27.662 88.4815 35.8425 89.4894C54.5264 91.7067 72.4023 90.4972 80.4818 86.4322C87.0465 83.1399 86.0365 78.3022 77.9233 74.17C76.6104 73.4981 76.0718 72.8933 75.0955 70.844C73.1093 66.7454 70.0458 64.3602 65.9724 63.789C64.5248 63.5875 64.2891 63.3859 63.8515 62.2101C63.5822 61.4374 62.5049 59.9256 61.495 58.7834C59.3741 56.5661 59.2058 55.7598 60.1484 52.2995C60.4514 51.1908 60.889 49.2423 61.1247 47.9657C61.7306 44.7742 63.1109 38.895 63.4475 37.6856C63.6495 37.148 64.3565 34.8972 65.0298 32.6463C65.7367 30.3954 67.016 26.9015 67.8576 24.8858C68.6992 22.8701 69.6418 20.5184 69.9448 19.6785C70.7191 17.562 74.7589 9.56638 76.2064 7.2819C76.9134 6.20686 77.452 5.03102 77.452 4.66148C77.4184 2.61217 68.8675 -0.0754479 65.1981 0.898813Z" fill="#78CCFB"/>
        <path d="M75.7689 7.0126C70.8875 15.0083 66.1744 27.1025 62.2357 41.4477C62 42.2876 61.6634 43.4298 61.495 43.9673C60.9901 45.5463 59.6772 51.3247 59.1722 54.2811L58.6672 57.0695L56.176 57.1031C53.4492 57.1367 50.3857 58.2117 50.0154 59.2867C49.6114 60.2946 50.0491 60.3282 51.8333 59.3203C55.0988 57.5398 58.5662 57.7078 61.4277 59.7907C62.5387 60.597 64.1209 62.8478 63.7506 63.1502C63.6832 63.2174 62.808 63.6541 61.899 64.0573C60.1148 64.83 58.3979 66.1738 58.3979 66.8457C58.3979 67.3832 58.4652 67.3832 60.9564 65.8714C63.5823 64.2924 65.6695 64.1245 68.7666 65.2667C72.3014 66.5433 75.0956 69.4661 75.0956 71.8514C75.0956 72.5233 74.9273 72.5569 73.547 72.1873C72.6381 71.9521 71.8301 71.9185 71.6954 72.0865C71.3925 72.3889 72.7054 73.4303 73.4797 73.4303C74.7253 73.4303 81.2225 76.9578 82.5691 78.3688C84.6563 80.5525 84.8247 81.8963 83.2424 83.7105C80.0106 87.3387 70.1805 89.6904 55.3007 90.3287C52.2036 90.4295 49.5777 90.6311 49.5104 90.6647C49.4768 90.7319 49.5104 91.0006 49.6787 91.2358C49.9144 91.5717 51.463 91.6389 55.4691 91.471C61.1247 91.2358 61.697 91.1686 67.5547 90.4295C77.8897 89.1529 85.6326 85.3902 85.6326 81.6612C85.6326 79.2087 83.0068 76.3867 78.967 74.4382C76.6105 73.3295 76.3412 73.0944 76.3412 72.0193C76.3075 68.2231 72.4697 64.6956 67.218 63.5533C65.2992 63.1502 65.0972 62.9822 64.4575 61.6384C63.8516 60.1938 62.202 58.5812 60.6198 57.607C59.9128 57.2038 59.9128 57.0359 60.3841 54.3147C60.6871 52.7357 61.495 49.1746 62.2357 46.4198C62.9763 43.6314 63.7842 40.4062 64.0872 39.264C65.1982 34.7286 69.3053 23.1383 71.6618 17.5951C72.9747 14.5379 75.8025 9.02831 77.3848 6.60946C78.4284 4.8961 78.462 4.76172 77.7214 4.76172C77.3511 4.79531 76.4758 5.80317 75.7689 7.0126Z" fill="black"/>
        <path d="M62.3366 6.81182C60.4851 9.53303 56.7483 16.7896 57.0176 17.1927C57.4553 17.8982 57.9602 17.2263 59.5761 13.9676C60.4851 12.2207 61.8653 9.70101 62.6733 8.3572C63.5149 7.01339 64.1882 5.73678 64.1882 5.5352C64.2219 4.72892 63.4139 5.30004 62.3366 6.81182Z" fill="black"/>
        <path d="M83.4444 9.49951C83.5454 10.8433 83.8484 12.5903 84.0167 13.3294C84.4543 15.1771 85.2959 21.4594 85.1276 21.6274C84.9593 21.7954 81.9968 21.3586 80.9195 21.0563C80.6502 20.9891 80.3472 21.0899 80.2126 21.325C80.0106 21.8961 81.8958 22.5345 84.791 22.8704C86.8445 23.1056 87.2148 23.2735 87.7198 24.2814C88.7634 26.2971 90.6823 37.4507 91.0526 43.7666C91.1536 45.5136 91.0863 45.7151 90.3793 45.5472C88.9654 45.312 88.8307 45.312 88.6961 45.648C88.6287 45.8831 89.2347 46.3199 90.11 46.6222C93.8468 48.1004 94.4527 49.6122 96.4053 61.7737C96.8766 64.6292 97.4152 67.1489 97.6172 67.3505C98.3242 68.0896 98.4252 67.1489 98.0549 64.5957C97.8192 63.1511 97.4489 60.7322 97.2132 59.254C96.6746 55.7601 95.3617 50.2169 94.7894 49.209C94.5537 48.7387 93.9814 47.966 93.5101 47.4285C92.7695 46.6222 92.5338 45.4464 91.7932 39.4664C90.3456 27.5401 89.1674 22.904 87.2148 22.0641C86.5415 21.7618 86.3059 21.0227 85.7672 17.428C84.4543 8.69323 84.185 7.38301 83.6464 7.18144C83.2424 7.04706 83.2087 7.51739 83.4444 9.49951Z" fill="black"/>
        <path d="M43.4844 12.8247C43.1478 14.0677 42.6765 15.5795 42.5082 16.2514C42.3062 16.8561 41.9359 18.3679 41.7002 19.5437C41.4309 20.7196 41.0942 22.0634 40.8923 22.5673C40.4209 23.7431 40.4209 25.3557 40.8923 25.3557C41.3636 25.3557 42.9795 19.8461 44.0904 14.5717C44.2587 13.6646 44.5954 12.5895 44.831 12.1528C45.2687 11.3801 45.0667 10.6074 44.4944 10.6074C44.2587 10.6074 43.8211 11.6153 43.4844 12.8247Z" fill="black"/>
        <path d="M63.9525 17.4614C61.1246 23.2061 60.687 24.1132 59.3741 27.8087C58.9028 29.0853 58.3978 30.4291 58.2968 30.7651C56.7819 34.6957 53.9541 44.6399 54.257 44.9758C54.762 45.4462 55.267 44.5055 56.1086 41.6163C58.9701 31.6049 62.6732 21.9631 65.7367 16.1847C67.4873 12.926 67.7229 12.1533 66.915 12.1533C66.7466 12.1533 65.4337 14.5722 63.9525 17.4614Z" fill="black"/>
        <path d="M36.3139 19.0402C35.5059 21.2575 34.7316 23.7435 33.5197 27.6742C32.8127 29.925 32.0385 32.4783 31.7355 33.4189C31.0622 35.4347 31.0622 35.4347 31.7355 35.4347C32.0385 35.4347 32.7117 34.0237 33.4524 31.6384C36.4149 22.3325 37.4921 18.7714 37.4921 18.5027C37.4585 17.6964 36.7178 18.0659 36.3139 19.0402Z" fill="black"/>
        <path d="M55.7384 24.2806C54.4254 25.994 49.2411 40.6415 48.1638 45.8824C47.8608 47.2934 45.235 57.8087 44.7973 59.4548C44.4607 60.6978 44.831 61.9073 45.3696 61.3026C45.6053 61.101 46.9855 55.5578 48.2985 49.9474C48.5678 48.8723 48.9044 47.4613 49.0727 46.8566C49.2747 46.1847 49.7797 44.4378 50.1837 42.9932C52.1699 35.9718 53.9541 30.8317 56.2097 25.658C56.782 24.247 56.5126 23.34 55.7384 24.2806Z" fill="black"/>
        <path d="M25.5411 38.7608C25.3728 39.164 24.9688 40.3398 24.5311 41.3477C23.7232 43.4642 23.6222 44.4384 24.2618 44.2704C25.0361 44.0017 26.7194 38.9288 26.248 38.4585C25.9114 38.1225 25.7094 38.2233 25.5411 38.7608Z" fill="black"/>
        <path d="M25.9115 46.1173C25.7431 46.5204 25.3392 47.629 24.9352 48.5697C24.0936 50.5518 23.9926 51.4253 24.6659 51.2573C25.4738 50.9886 27.1234 46.3188 26.6184 45.8149C26.3154 45.5125 26.1135 45.5797 25.9115 46.1173Z" fill="black"/>
        <path d="M7.4969 47.2938C7.4969 47.4617 8.1702 49.6118 8.94448 52.1315C9.7861 54.9871 10.5267 56.734 10.897 56.734C11.604 56.734 11.5703 56.4988 10.4594 53.2065C9.95442 51.6275 9.34846 49.6118 9.11281 48.6712C8.84349 47.6969 8.40585 47.0586 8.10287 47.0586C7.76622 47.0586 7.4969 47.1594 7.4969 47.2938Z" fill="black"/>
        <path d="M13.0516 51.5935C13.1526 54.8858 13.3209 55.7929 13.7586 55.7929C14.0952 55.7929 14.2972 55.3897 14.2635 54.7178C14.0952 47.4612 14.0952 47.4612 13.4892 47.4612C12.9843 47.4277 12.9169 48.0324 13.0516 51.5935Z" fill="black"/>
        <path d="M22.1073 49.0741C21.6023 49.9139 19.9191 54.8524 19.9191 55.6251C19.9191 55.8267 20.1548 55.9611 20.4241 55.9611C20.6597 55.9611 21.4004 54.3485 22.04 52.232C23.2183 48.6373 23.2856 47.2935 22.1073 49.0741Z" fill="black"/>
        <path d="M29.2779 52.9037C28.7729 55.5242 28.8739 56.4984 29.5809 55.7257C29.8502 55.4234 30.7928 51.3919 30.7928 50.4513C30.7928 50.2833 30.5572 50.1489 30.2879 50.1489C29.9512 50.1489 29.6146 51.1232 29.2779 52.9037Z" fill="black"/>
        <path d="M85.8009 50.8546C85.397 51.4593 87.2822 59.8581 87.8208 59.8581C88.4941 59.8581 88.3931 58.9174 87.4842 54.7516C86.6762 51.1233 86.2386 50.1491 85.8009 50.8546Z" fill="black"/>
        <path d="M90.8506 56.7006C90.6823 56.9022 90.6823 57.2717 90.7833 57.6077C90.8843 57.8764 91.1873 59.3882 91.3892 60.9C91.6922 62.8821 91.9952 63.722 92.3992 63.722C93.0725 63.722 93.0725 63.9571 92.3319 59.7913C91.7596 56.5998 91.3556 55.8607 90.8506 56.7006Z" fill="black"/>
        <path d="M21.1984 60.7653C19.8855 61.37 18.7409 62.277 18.0003 63.3521C16.721 65.099 15.8121 67.6859 16.1824 68.6265C16.519 69.4328 17.125 68.8953 17.3943 67.4507C18.0003 64.6287 19.1449 63.2177 22.0064 61.6723C23.2856 61.0004 24.0262 60.8996 26.2144 61.0676C28.4363 61.2692 29.2106 61.5043 30.9948 62.6802C32.1731 63.4529 33.1157 64.2592 33.1157 64.4607C33.1157 64.6959 33.385 64.8639 33.7217 64.8639C35.6742 64.8639 31.7018 61.1012 28.8403 60.2613C26.4164 59.5222 23.4539 59.7574 21.1984 60.7653Z" fill="black"/>
        <path d="M101.893 63.9234C99.9738 67.9212 99.6035 69.1643 100.31 69.1643C100.58 69.1643 105.023 60.5639 105.023 60.0264C105.023 59.9592 104.754 59.8584 104.451 59.8584C104.081 59.8584 103.105 61.4374 101.893 63.9234Z" fill="black"/>
        <path d="M106.235 65.771C103.542 68.4587 102.869 69.5673 103.946 69.5001C104.788 69.4329 109.838 63.7553 109.434 63.3522C109.198 63.117 108.087 63.9569 106.235 65.771Z" fill="black"/>
        <path d="M38.3674 71.3141C37.6941 71.6164 36.6505 72.3891 36.0445 73.0274C35.2029 73.8337 34.7316 74.0689 34.294 73.8001C33.2504 73.0946 29.3116 73.3634 27.6957 74.2032C25.5075 75.3119 23.4876 78.4698 24.9688 78.4698C25.1708 78.4698 25.6421 77.9323 26.0125 77.2604C27.4937 74.7408 31.6345 73.8001 34.6979 75.3455C36.2802 76.1854 37.3911 76.0846 36.6168 75.1439C36.0109 74.3712 36.5832 73.4306 38.3001 72.5907C40.1516 71.6164 43.0131 71.818 45.0667 73.0274C45.8073 73.4641 46.4132 73.5985 46.5479 73.3634C47.5578 71.818 40.7576 70.071 38.3674 71.3141Z" fill="black"/>
        <path d="M1.57185 74.1365C1.23521 74.7413 0.89856 75.9171 0.89856 76.7234C0.89856 78 1.16788 78.4703 2.95211 80.1837C4.02938 81.2587 5.57795 82.4346 6.31857 82.7705C10.0554 84.4839 11.4356 85.0214 14.4654 85.9621C21.2994 88.0786 27.157 89.288 35.6068 90.2622C40.4546 90.7998 41.2625 90.7998 41.2625 90.0607C41.2625 89.7247 40.8585 89.6239 34.4622 88.8848C30.6581 88.4481 24.8005 87.4402 22.6123 86.9363C21.8717 86.7347 20.1884 86.3316 18.9092 85.9957C10.796 83.9799 5.00565 81.2923 2.81745 78.5375C1.87484 77.3281 1.74018 75.2116 2.58179 74.5061C2.88478 74.2373 3.15409 73.767 3.15409 73.4982C3.22142 72.6248 2.24515 72.9943 1.57185 74.1365Z" fill="black"/>
        <path d="M51.5976 79.0073C49.7797 79.8472 47.8945 81.9301 47.8945 83.0387C47.8945 83.7778 48.1302 83.6098 50.1164 81.6277C51.3283 80.4183 52.4729 79.6456 53.3145 79.5112C57.4553 78.6713 61.0911 80.0152 63.1446 83.1395C63.9862 84.4833 64.4912 84.9201 64.7605 84.6513C65.3665 84.0466 63.9189 81.5941 61.9663 80.1159C58.9702 77.8315 54.9304 77.3947 51.5976 79.0073Z" fill="black"/>
        </svg>

    </>
  )
}

export default Cascade
 