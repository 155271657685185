import React from 'react'
import BackButton from './BackButton'
import Close from './svg/Close'

const About = ({setIsAbout, setOpenMenu, setActivePopup}) => {
  return (
    <div className='w-full'>
        <div className='flex justify-between absolute top-0 left-0 w-full px-[20px] lg:px-[35px] max-w-[1440px mx-auto]]
        
           '>
            <BackButton setIsAbout={setIsAbout} />
            <Close setOpenMenu={setOpenMenu} setIsAbout={setIsAbout} setActivePopup={setActivePopup} />
        </div>
        

        <h2 className='text-center font-roboto-flex text-white text-[32px] md:text-[40px] lg:text-[40px] 
        xl:text-[48px] mb-[30px]'>About Us</h2>
        <div className='w-[80%] mx-auto'>
        <p>Welcome to Tellus Cooperative, your gateway to a brighter future powered by cryptocurrencies and
           decentralized autonomous organizations (DAOs). We are a dynamic force in the Stellar Ecosystem, 
           driven by a mission to revolutionize Chile and LATAM’s financial landscape by empowering communities
            through blockchain technology.</p>
        <h3 className='font-roboto-flex text-white text-[20px] md:text-[25px] lg:text-[30px] 
        xl:text-[32px] mb-[10px]'>Our Vision</h3>
        <p>With the generous support of the Stellar Community Fund, we have embarked on a journey to transform 
          the way Chileans interact with cryptocurrencies and engage in DAOs. Our vision encompasses three
           pivotal products and services that will reshape the financial and social fabric of Chile and Latin America.</p>
        <h3 className='font-roboto-flex text-white text-[20px] md:text-[25px] lg:text-[30px] 
        xl:text-[32px] mb-[10px]'>Our Commitment</h3>
        <p>At Tellus Cooperative, our commitment is twofold. We are dedicated to fostering open-source 
          collaboration through our projects, driving innovation within the Stellar community. Simultaneously,
           we understand the need for sustainable monetization strategies to support our ongoing efforts.</p>
           
        <p>Our primary goal remains unwavering: to accelerate cryptocurrency adoption in Chile and Latin America
           while cultivating a thriving Stellar ecosystem that empowers individuals to learn, cooperate, and flourish.
            As we embrace the potential of blockchain technology, we invite you to join us on this transformative journey.
             Together, we will unlock the full potential of cryptocurrencies and DAOs within the Stellar community.</p>
        </div>
        
    </div>
  )
}

export default About