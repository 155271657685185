import React from 'react';
import Logo from './Logo';
import Button from './Button';
import Mapa from './Mapa';
import { useMediaQuery } from 'react-responsive';
import MapaDark from './MapaDark';
import Winners from './Winners';
import Switch from './Switch';
import Elements from './Elements';
 

const MainContent = ({darkMode, openPlayer=false, setOpenPlayer, setActivePopup, setDarkMode}) => {
    const isDesktop = useMediaQuery({minWidth: 1024});
    const isMobile = useMediaQuery({maxWidth: 1023});

    const handleClickYoutube = e => {
        const id = 'cydEMqD514c';
        setOpenPlayer(true)
        setActivePopup(true)
    }


  return (
    <div className='main-content relative flex justify-between 
        flex-row-reverse flex-wrap 
        lg:flex-nowrap px-[25px] pt-[27vw] sm:pt-[100px] lg:px-[60px] lg:py-[20px]
        max-w-[1440px] mx-auto overflow-hidden ]'>
        <div className='map w-full lg:w-1/2 relative z-[4] '> 
            <div className='container-map'>
                {isMobile && <div className='absolute w-[33%] left-0 top-[40%] flex flex-col justify-center'>
                     <Winners handleClickYoutube={handleClickYoutube} />
                </div> }
                {!darkMode && <Mapa />}
                {darkMode && <MapaDark />}
                <Elements />
            </div>
            
        </div>
        <div className='content w-full lg:w-1/2 pt-[25px] lg:pt-[140px] relative z-[5] pb-10 sm:pb-16 lg:pb-0'>
            { isDesktop && <Logo/>}
            <h1 className='text-white text-[4.35vw] min-[420px]:text-[5.35vw] 
            sm:text-[25px] md:text-[30px] xl:text-[38px] min-[1400px]:text-[45px] font-[600] 
            tracking-[4px] leading-[1.125em] mb-[15px] lg:mb-[60px] font-roboto-flex'>
                COOPERATE WITH PEERS.<br/>INVEST COLLECTIVELY.
            </h1> 
            <p className=' text-white font-roboto-mono text-[4vw] min-[420px]:text-[15px] 
                        md:max-w-[75%] min-[800px]:max-w-[80%] lg:max-w-none
                        sm:text-[16px] lg:text-[24px] xl:text-[27px] font-[500]
                         leading-[20px] lg:leading-8 mb-[20px] lg:mb-[55px]'>
                Designing the future of sustainable finance in Latin America
            </p>
            {isMobile && <span className='mb-[20px] block'><Button type='youtube' 
                    action={handleClickYoutube}
               /></span> } 
            {isDesktop && <Winners handleClickYoutube={handleClickYoutube} />}

            <Switch setDarkMode={setDarkMode} darkMode={darkMode} />
        </div>
    </div>
  )
}

export default MainContent