import React from 'react'

const MediumSvg = () => {
  return (
    <svg width="48" height="27" viewBox="0 0 48 27" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M27.075 13.5C27.075 20.955 21.015 27 13.536 27C11.7608 27.0024 10.0025 26.6551 8.36151 25.9779C6.72053 25.3007 5.22899 24.307 3.97205 23.0534C2.71512 21.7998 1.71741 20.3109 1.03588 18.6718C0.354355 17.0326 0.00236222 15.2752 0 13.5C0 6.04201 6.06 1.63317e-05 13.536 1.63317e-05C15.3115 -0.00274339 17.0701 0.344277 18.7114 1.02126C20.3527 1.69824 21.8446 2.69192 23.1019 3.94555C24.3591 5.19918 25.3571 6.6882 26.0388 8.32757C26.7205 9.96695 27.0726 11.7246 27.075 13.5ZM41.925 13.5C41.925 20.52 38.895 26.208 35.157 26.208C31.419 26.208 28.389 20.517 28.389 13.5C28.389 6.48001 31.419 0.792016 35.157 0.792016C38.895 0.792016 41.925 6.48301 41.925 13.5ZM48 13.5C48 19.788 46.935 24.885 45.618 24.885C44.304 24.885 43.239 19.785 43.239 13.5C43.239 7.21201 44.304 2.11502 45.621 2.11502C46.935 2.11502 48 7.21201 48 13.5Z" fill="white"/>
    </svg>

  )
}

export default MediumSvg