import React from 'react'

const Switch = ({darkMode, setDarkMode}) => {

  const handleOnChange = e => {
    console.log(e);
    console.log(e.target);
    console.log(e.target.checked);
    setDarkMode(e.target.checked);

  }

  return (
    <div className='switch'>
        <input 
            type='checkbox' 
            id='switch-button' 
            onChange={handleOnChange}/>
        <label htmlFor="switch-button">Toggle</label>
    </div>
  )
}

export default Switch