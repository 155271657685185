import React from 'react'; 
import LogoSvg from './svg/LogoSvg';

const Logo = () => {
  return (
    <div className='logo inline-block mb-12 p-0 max-w-[500px]'> 
        <LogoSvg /> 
    </div>
  )
}

export default Logo