import React from 'react'
import { useMediaQuery } from 'react-responsive'

const HamburgerMenu = ({openMenu, setOpenMenu, setActivePopup, darkMode, setIsAbout}) => {
    const isDesktop = useMediaQuery({minWidth: 1024})

    const handleClick = e => {
        if(openMenu){
            setActivePopup(false);
        }else{
            setActivePopup(true);
        }
        setOpenMenu(! openMenu);
        setIsAbout(false)
    }
    const opened = openMenu ? 'opened': '';
    const css = darkMode ? 'hover:text-[#D44967]': 'hover:text-[#22333F]';
    return (
        <div className={`inline-flex  container-hamburger items-center cursor-pointer text-white
        ${css}`} 
            onClick={handleClick}>
            <div 
                className={`hamburger-menu text-inherit  ${opened}`}>
                <span></span>
            </div>
            {isDesktop && <span className='ml-[20px] text-inherit text-[18px] 
            inline-block tracking-[4px] selection:bg-transparent'> MENU</span>}
        </div>
    )
}

export default HamburgerMenu