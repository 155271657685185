import React from 'react'

const Boat = () => {
  return (
    <>
        <svg width="96" height="79" viewBox="0 0 96 79" fill="none" 
        xmlns="http://www.w3.org/2000/svg" className='element-item' id='boat'>
            <path fillRule="evenodd" clipRule="evenodd" d="M59.8146 47.7862C58.2764 47.9544 4.2371 54.0742 3.30077 54.2087C2.83261 54.276 2.29757 54.5113 2.19725 54.7803C1.92972 55.4529 5.70846 62.0435 8.88528 66.3476C11.0254 69.273 11.828 70.1136 12.4968 70.1809C13.801 70.2817 17.145 68.7013 19.3186 66.9528L21.3585 65.3388L23.6658 67.0201C27.8793 70.08 32.0593 71.2233 35.5705 70.2817C37.6104 69.7101 41.3222 67.7935 44.3318 65.6414L46.8399 63.8929L48.4115 65.1034C49.281 65.7759 50.418 66.4484 51.0199 66.6502L52.0565 66.9192L57.3735 57.8739C60.5169 52.5611 62.6571 48.5932 62.5567 48.257C62.4564 47.719 61.6204 47.6181 59.8146 47.7862Z" fill="#FDE964"/>
            <path className='to-animate' fillRule="evenodd" clipRule="evenodd" d="M22.0273 0.441278C21.7264 0.710282 22.1611 6.59476 23.4318 20.0114C24.4016 30.5698 25.2041 39.2116 25.2376 39.2116C25.2376 39.2452 25.4717 39.3797 25.7392 39.5142C26.6086 39.9177 29.2504 38.0347 35.5706 32.5201C41.2889 27.5435 46.6393 22.2643 47.6759 20.6839C48.2444 19.776 47.8431 19.3389 45.1345 17.9938C41.0213 15.9427 32.7951 10.4617 29.1166 7.33452C27.8125 6.19125 25.7726 4.10647 24.5353 2.66057C22.8968 0.676657 22.2614 0.138647 22.0273 0.441278Z" fill="#DD211E"/>
            <path d="M21.4588 0.273207C21.3585 0.441335 22.4286 11.9413 23.8331 25.8287C25.2376 39.716 26.3411 51.0815 26.3076 51.1151C26.2742 51.1487 20.9238 51.7876 14.4029 52.561C7.91555 53.3344 2.46481 54.0742 2.33105 54.1414C1.89633 54.3768 1.96321 54.9148 2.43137 54.9484C3.33425 55.0493 61.6873 47.887 62.0217 47.6517C63.1587 46.7438 60.1491 46.9792 44.6663 48.8958C35.303 50.0391 27.5784 50.9133 27.5115 50.8797C27.4446 50.8125 26.2742 39.4806 24.8697 25.7278C23.4652 11.9749 22.1611 0.542212 21.9939 0.340458C21.7932 0.0714535 21.5926 0.0714535 21.4588 0.273207Z" fill="black"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M48.5453 59.7904C44.2984 59.9585 41.9576 55.5872 45.0341 53.1326C45.937 52.46 46.4386 52.3255 47.8765 52.4264C49.7826 52.5609 51.2206 53.4016 51.8225 54.7802C52.926 57.134 51.254 59.6895 48.5453 59.7904ZM46.9402 58.4115C50.1839 59.6557 52.625 56.1923 49.883 54.242C48.5788 53.3005 47.0071 53.1996 45.8367 53.973C44.1312 55.1835 44.7332 57.5709 46.9402 58.4115Z" fill="black"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M27.6786 59.1176C26.8426 57.1337 27.6117 55.2171 29.5847 54.4101C32.5274 53.1995 35.7043 54.9817 35.8715 57.9071C35.9718 59.5211 35.4367 60.4627 33.9654 61.2024C31.7249 62.413 28.6484 61.3369 27.6786 59.1176ZM34.9352 58.3106C35.0021 57.4027 34.0992 56.1249 32.9288 55.5533C32.2547 55.2061 31.912 55.0296 31.5629 55.0158C31.202 55.0016 30.8343 55.1613 30.0864 55.486C28.9829 55.9232 28.8825 56.0913 28.7822 57.4699C28.6819 58.7813 28.8157 59.0503 29.7854 59.8237C30.6549 60.4962 31.1899 60.6644 32.0928 60.5971C33.3301 60.4962 34.8683 59.2857 34.9352 58.3106Z" fill="black"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M11.7612 56.9992C12.2628 56.4612 13.1991 55.9232 13.7676 55.7887C15.3727 55.4525 16.2756 55.5197 17.446 56.1586C20.4556 57.7054 20.4891 61.3033 17.5129 62.7492C13.2325 64.7667 8.65124 60.2273 11.7612 56.9992ZM18.3154 60.53C18.8839 59.5885 18.6833 58.6469 17.6466 57.7054C15.774 55.9905 12.2627 56.8984 12.0955 59.185C11.9618 60.7654 13.1322 61.8078 15.2055 61.9423C16.8775 62.0095 17.6132 61.7069 18.3154 60.53Z" fill="black"/>
            <path d="M71.0506 64.3298C65.4326 69.0374 59.915 70.2479 54.3305 68.0623C52.2237 67.2889 48.8463 64.9351 48.0103 63.7918C47.4752 63.0184 47.0405 62.9848 46.9736 63.7582C46.9402 64.0944 47.609 64.9351 48.445 65.6748C55.5343 71.8956 64.7972 71.5257 72.5219 64.6661L74.2608 63.1529L76.2672 65.0696C81.9186 70.4833 88.8073 71.9628 93.9905 68.9029C95.5288 67.9614 96.1641 66.9862 95.1944 66.919C94.9603 66.919 94.559 67.1207 94.258 67.3897C93.2883 68.4321 90.3455 69.3737 88.0382 69.3737C84.226 69.3737 79.3772 67.0199 76.2672 63.59C75.5984 62.8503 74.7959 62.1105 74.5283 62.0096C74.2608 61.8415 72.9566 62.7494 71.0506 64.3298Z" fill="black"/>
            <path d="M19.2852 66.5493C16.1084 69.4411 10.758 70.7861 6.7117 69.7437C4.43777 69.1721 2.66544 68.3314 1.36128 67.289C0.391512 66.482 -0.176971 66.6165 0.291192 67.5581C0.859674 68.5668 4.03649 70.2817 6.47762 70.8197C10.992 71.8621 16.5097 70.4835 19.8202 67.5581L21.3919 66.1794L22.9636 67.3563C25.8395 69.6428 30.287 71.3241 33.1629 71.1896C35.3699 71.0887 39.4831 69.5083 38.9815 68.9367C38.7474 68.6341 38.0786 68.7349 36.5738 69.3066C35.4368 69.7437 33.7648 70.08 32.8619 70.1472C30.1533 70.2481 25.572 68.3987 22.7964 65.9776C22.2614 65.5069 21.5926 65.0698 21.3585 65.1034C21.1244 65.137 20.1881 65.7423 19.2852 66.5493Z" fill="black"/>
            <path d="M43.7633 72.3671C40.62 74.9226 34.9351 76.772 30.9892 76.503C29.2837 76.3685 26.1069 75.2589 25.0703 74.3846C24.5018 73.9139 24.1005 73.7457 23.9668 73.9811C23.5989 74.5191 24.2343 75.2253 26.1069 76.2004C28.2805 77.3773 33.0625 77.7808 36.1724 77.0747C39.0483 76.4358 42.5929 74.7881 44.499 73.275C46.0707 71.9972 46.1376 71.9636 46.6058 72.6361C47.3749 73.5776 50.6186 75.696 53.2938 76.9065C56.27 78.2516 59.2796 78.3861 62.2892 77.2428C63.7606 76.6712 64.2287 76.3349 64.0281 76.0995C63.794 75.7969 63.0249 75.8978 61.4532 76.4358C58.6442 77.3437 56.504 77.2092 53.7619 75.9987C51.2539 74.889 47.7762 72.6025 47.007 71.5937C46.6726 71.1566 46.2714 70.8203 46.0707 70.8203C45.8032 70.8539 44.8 71.5264 43.7633 72.3671Z" fill="black"/>
        </svg>

    </>
  )
}

export default Boat