import React from 'react';
import LogoStellar from './svg/LogoStellar'; 
import LogoStellar12 from './svg/LogoStellar12'; 
import Button from './Button';
import { useMediaQuery } from 'react-responsive';


const Winners = ({handleClickYoutube}) => {

    const isDesktop = useMediaQuery({minWidth: 1024});
    const isMobile = useMediaQuery({maxWidth: 767})
  return (
    <>
    <h3 className='font-roboto-mono letter-spacing: 4.5px; text-white 
            mb-[15px] text-[2.4vw] sm:text-base font-bold leading-none tracking-[3.5px] '>
                Winners of:
    </h3>
    <div className='footer-content flex-col lg:flex-row flex justify-center lg:justify-normal items-center '>
        <span className='lg:mr-[20px] xl:mr-[25px] w-full lg:w-auto mx-0 lg:max-w-[24%] xl:max-w-[40%] svg-max-full mb-[20px] lg:mb-0'><LogoStellar /></span>
        <span className="lg:mr-[40px] xl:mr-[75px] w-full lg:w-auto mx-0 lg:max-w-[28%] xl:max-w-[40%] svg-max-full"><LogoStellar12 /></span>
        {isDesktop && <span><Button type='youtube' 
                action={handleClickYoutube}
            /></span> }
    </div>
    </>
    
  )
}

export default Winners