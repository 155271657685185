import React from 'react'

const BackButton = ({setIsAbout}) => {

  const handleOnClick = e => {
    e.preventDefault();
    setIsAbout(false);
    //setIsAbout(false);
  }

  return (
    <button className='svg-button inline-block w-[30px] h-[30px] m-0 p-0 outline-none bg-transparent' onClick={handleOnClick}  >
        <svg width="48" height="48" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg" className='inline-block w-full h-full'>

        <g>
        <title>background</title>
        <rect fill="none" id="canvas_background" height="402" width="582" y="-1" x="-1"/>
        </g>
        <g>
        <title>Layer 1</title>
        <path id="svg_1" fill="none" d="m0,0l48,0l0,48l-48,0l0,-48z"/>
        <path stroke="null" className='fill' fill="#ffffff" d="m47.604702,21.049414l-35.90865,0l16.479031,-16.479031l-4.175081,-4.175081l-23.6047,23.6047l23.6047,23.6047l4.175081,-4.175081l-16.479031,-16.479031l35.90865,0l0,-5.901175z"/>
        </g>
        </svg>
    </button>
  )
}

export default BackButton